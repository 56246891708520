<template>
  <div>
    <Header></Header>
    <div class="wisdom">
      <div class="banner">
        <div class="banner-box2 box">
          <div class="banner-title wow fadeInUp">物联网智慧医疗</div>
          <div class="banner-detail wow fadeInUp">为基于物联网的各行业设备制造商、方案商提供一站式应用开发服务。通过统一的、高度集成的物联网平台，使各种应用功能基于同一个平台进行呈现、使用、管理。让数据流动成为精细化管理的大脑，更有效率的流转，更有默契的协同。</div>
        </div>
        <img :src="`${$img}CloudNumber/banner/3.png?v=1`" alt="" class="banner-img">
      </div>
      <div class="ProductBg">
        <div class="box" style="position: relative;">
          <div id="Product" style="position: absolute;top:-80px"></div>
          <div class="All-title wow fadeInUp" data-wow-delay="0.1s">
            <div class="cnmmon-text">
              产品优势
              <div class="cnmmon-line"></div>
            </div>
          </div>
          <div class="advantages">
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.2s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/16.png`" alt="">
              </div>
              <div class="as-title">应用层</div>
              <div class="as-text">基于历史交付和实践经验所设计的应用模板，可帮助客户有效缩减应用的开发时间。</div>
            </div>
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.2s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/17.png`" alt="">
              </div>
              <div class="as-title">平台层</div>
              <div class="as-text">完善的可视化开发和管理功能，帮助客户轻松完成设备端的"管、控、营"一体化。</div>
            </div>
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.2s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/18.png`" alt="">
              </div>
              <div class="as-title">传输层</div>
              <div class="as-text">借助智能网关及SDK，帮助客户快速接入各类设备，让客户的数据更迅速、安全的流动。</div>
            </div>
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.3s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/19.png`" alt="">
              </div>
              <div class="as-title">感知层</div>
              <div class="as-text">广泛兼容标准协议，面对各种复杂的设备部署方案游刃有余, 帮助客户有效降低实施成本。</div>
            </div>
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.3s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/20.png`" alt="">
              </div>
              <div class="as-title">体验层</div>
              <div class="as-text">使用云数智康平台可以轻松的开发跨平台的物联网应用，以高度灵活的适配数据需求。</div>
            </div>
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.3s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/21.png`" alt="">
              </div>
              <div class="as-title">开发层</div>
              <div class="as-text">告别各类工具的沉重束缚，使用桌面端，移动端访问, 有效降低开发运维的成本和复杂度。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="technicalAdvan-bg" style="position: relative;">
        <div id="technicalAdvan" style="position: absolute;top:-80px"></div>
        <div class="box">
          <div class="All-title wow fadeInUp" data-wow-delay="0.1s">
            <div class="cnmmon-text">
              技术优势
              <div class="cnmmon-line"></div>
            </div>
          </div>
          <div class="technicalAdvan technicalAdvan2">
            <div class="technicalAdvan-item wow fadeInUp" data-wow-delay="0.1s">
              <div class="technicalAdvan-top">
                <img :src="`${$img}/CloudNumber/home/29.png`" alt="">
              </div>
              <div class="te-title">更加轻量</div>
              <div class="te-text">基于历史交付和实践经验所设计的应用模板，可帮助客户有效缩减应用的开发时间，提升交付品质。</div>
            </div>
            <div class="technicalAdvan-item wow fadeInUp" data-wow-delay="0.1s">
              <div class="technicalAdvan-top">
                <img :src="`${$img}/CloudNumber/home/30.png`" alt="">
              </div>
              <div class="te-title">更加强大</div>
              <div class="te-text">真正开箱即用，全力推动客户价值的极速落地可视化的开发方式轻松打造物联网应用。</div>
            </div>
            <div class="technicalAdvan-item wow fadeInUp" data-wow-delay="0.1s">
              <div class="technicalAdvan-top">
                <img :src="`${$img}/CloudNumber/home/31.png`" alt="">
              </div>
              <div class="te-title">更加简单</div>
              <div class="te-text">在线应用使能平台，可视化的开发方式，轻松打造物联网应用。</div>
            </div>
            <div class="technicalAdvan-item wow fadeInUp" data-wow-delay="0.2s">
              <div class="technicalAdvan-top">
                <img :src="`${$img}/CloudNumber/home/32.png`" alt="">
              </div>
              <div class="te-title">更加柔性</div>
              <div class="te-text">在线热更新，避免停服升级造成的延误和损失，极速响应业务变更。</div>
            </div>
            <div class="technicalAdvan-item wow fadeInUp" data-wow-delay="0.2s">
              <div class="technicalAdvan-top">
                <img :src="`${$img}/CloudNumber/home/33.png`" alt="">
              </div>
              <div class="te-title">更加纯粹</div>
              <div class="te-text">更纯粹的开放平台，支持纵向扩展，以打造用户的自有物联网平台。</div>
            </div>
            <div class="technicalAdvan-item wow fadeInUp" data-wow-delay="0.2s">
              <div class="technicalAdvan-top">
                <img :src="`${$img}/CloudNumber/home/34.png`" alt="">
              </div>
              <div class="te-title">更加智能</div>
              <div class="te-text">使用云数智康通用网关接入各种现场设备，从容应对现场环境。</div>
            </div>
          </div>

        </div>
      </div>
      <div class="box" style="position: relative;">
        <div id="Value" style="position: absolute;top:-100px"></div>
        <div class="All-title wow fadeInUp" data-wow-delay="0.1s">
          <div class="cnmmon-text">
            方案价值
            <div class="cnmmon-line"></div>
          </div>
        </div>
        <div class="productValue wow fadeInUp" data-wow-delay="0.3s">
          <div class="product-item">
            <div class="product-top1">
              <img :src="`${$img}/CloudNumber/home/35.png`" alt="">
            </div>
            <div class="product-title">降低运营成本</div>
            <div class="product-text">通过分散采集，统一监控、集中管理减少维护成本、降低劳动强度，改善工作环境变分散式维护管理为集中式维护管理。</div>
          </div>
          <div class="product-item">
            <div class="product-top1">
              <img :src="`${$img}/CloudNumber/home/36.png`" alt="">
            </div>
            <div class="product-title">增强产品竞争力</div>
            <div class="product-text">由单纯卖设备转换为提供设备+服务，基于系统对产品运行信息分析，为今后产品改进提供强大的数据。</div>
          </div>
          <div class="product-item">
            <div class="product-top1">
              <img :src="`${$img}/CloudNumber/home/37.png`" alt="">
            </div>
            <div class="product-title">提高服务水平</div>
            <div class="product-text">实时了解设备的故障信息并做相应的决策和处理，基于系统反馈的故障处理信息，不断的改进服务方式，提高服务量。</div>
          </div>
        </div>

      </div>
    </div>
    <Footer @currentChange="currentChange"></Footer>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
    if (this.$route.params.id) {
      document.querySelector(`#${this.$route.params.id}`).scrollIntoView(true);
    }

  },
  beforeDestroy() {
    this.wow.stop();
  },
  methods: {
    currentChange(a) {
      document.querySelector(`#${a}`).scrollIntoView(true);
    }
  },
  data() {
    return {
      wow: null,
    }
  }
}
</script>

<style>
.technicalAdvan2 .technicalAdvan-top {
  background: transparent !important;
}
.ProductBg {
  overflow: hidden;
  background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber/home/46.png')
    no-repeat;
  background-size: cover;
}
</style>





