import router from './router'




//   每次切换tab时让滚动条回到原点
router.beforeEach(async (to, from, next) => {
  document.documentElement.scrollTop = 0
  next()
})
