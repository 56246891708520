<template>
  <div>
    <Header></Header>
    <div class="aboutUs">
      <div class="banner">
        <div class="banner-box2 box">
          <div class="banner-title wow fadeInUp">关于我们</div>
          <div class="banner-detail wow fadeInUp">
            用先进技术帮助医疗机构提供高品质、可信赖的医疗服务赋能医疗机构全方位数智化转型，实现高质量可持续发展，帮助医疗机构开源、节流、增效，从优秀到卓越。
          </div>
        </div>
        <img
          :src="`${$img}CloudNumber/banner/5.png?v=1`"
          alt=""
          class="banner-img"
        />
      </div>
      <div style="background: #fafafb; overflow: hidden; position: relative">
        <div id="Company" style="position: absolute; top: -80px"></div>
        <div class="box">
          <div class="All-title wow fadeInUp" data-wow-delay="0.1s">
            <div class="cnmmon-text">
              企业简介
              <div class="cnmmon-line"></div>
            </div>
          </div>
          <div class="CompanyProfile wow fadeInUp" data-wow-delay="0.3s">
            <div class="Company-left">
              <p>
                云数智康医疗立足北京，辐射全国，深耕大健康、人工智能大数据、互联网+智慧医疗等前沿领域，覆盖医疗数字化生态产品
                (智慧医院、互联网医疗、医疗大数据、医疗器械等)，通过科技引领、动态赋能、持续创新，始终专注于医疗数字化、医疗智能化的研发与升级，为全国医疗机构提供高品质的产品与服务。
              </p>
              <p>
                多年来，云数智康以提高医疗机构经营效能为己任，提供基于信息化技术及数据价值的智慧医疗整体解决方案，赋能医疗机构全方位数字化转型，实现高质量高发展。
              </p>
            </div>

            <img :src="`${$img}CloudNumber/abouts/1.png`" alt="" />
          </div>
        </div>
      </div>
      <div class="advantagesBg">
        <div class="box" style="position: relative">
          <div
            id="aboutUsAdvantages"
            style="position: absolute; top: -80px"
          ></div>
          <div class="All-title wow fadeInUp" data-wow-delay="0.1s">
            <div class="cnmmon-text">
              核心优势
              <div class="cnmmon-line"></div>
            </div>
          </div>
          <div class="advantages">
            <div class="advantages-item wow fadeInUp" data-wow-delay="0.2s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/16.png`" alt="" />
              </div>
              <div class="as-title">应用层</div>
              <div class="as-text">
                基于历史交付和实践经验所设计的应用模板，可帮助客户有效缩减应用的开发时间。
              </div>
            </div>
            <div class="advantages-item wow fadeInUp" data-wow-delay="0.2s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/17.png`" alt="" />
              </div>
              <div class="as-title">平台层</div>
              <div class="as-text">
                完善的可视化开发和管理功能，帮助客户轻松完成设备端的"管、控、营"一体化。
              </div>
            </div>
            <div class="advantages-item wow fadeInUp" data-wow-delay="0.2s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/18.png`" alt="" />
              </div>
              <div class="as-title">传输层</div>
              <div class="as-text">
                借助智能网关及SDK，帮助客户快速接入各类设备，让客户的数据更迅速、安全的流动。
              </div>
            </div>
            <div class="advantages-item wow fadeInUp" data-wow-delay="0.3s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/19.png`" alt="" />
              </div>
              <div class="as-title">感知层</div>
              <div class="as-text">
                广泛兼容标准协议，面对各种复杂的设备部署方案游刃有余,
                帮助客户有效降低实施成本。
              </div>
            </div>
            <div class="advantages-item wow fadeInUp" data-wow-delay="0.3s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/20.png`" alt="" />
              </div>
              <div class="as-title">体验层</div>
              <div class="as-text">
                使用云数智康平台可以轻松的开发跨平台的物联网应用，以高度灵活的适配数据需求。
              </div>
            </div>
            <div class="advantages-item wow fadeInUp" data-wow-delay="0.3s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/21.png`" alt="" />
              </div>
              <div class="as-title">开发层</div>
              <div class="as-text">
                告别各类工具的沉重束缚，使用桌面端，移动端访问,
                有效降低开发运维的成本和复杂度。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="position: relative">
        <div id="aboutUsMilepost" style="position: absolute; top: -80px"></div>
      </div>
      <div style="background: #fafafb; overflow: hidden">
        <div class="box">
          <div class="All-title wow fadeInUp" data-wow-delay="0.1s">
            <div class="cnmmon-text">
              荣誉资质
              <div class="cnmmon-line"></div>
            </div>
          </div>
          <div
            class="milepost wow fadeInUp"
            data-wow-delay="0.2s"
            style="background-color: #fafafb; padding: 0 0"
          >
            <div class="swiper-fled box" style="position: relative">
              <div
                class="wow fadeInUp box"
                data-wow-delay="0.5s"
                style="overflow: hidden"
              >
                <div class="swiper-box">
                  <div
                    class="swiper-wrapper"
                    style="
                      display: flex;
                      justify-content: space-between;
                      width: 1600px;
                    "
                  >
                    <div class="swiper-slide my-swiper-slide">
                      <div class="date-item">
                        <img
                          src="https://static.drlianzhuren.com/HairTransplant/CloudNumber/902.png"
                          alt=""
                        />
                      </div>
                      <div class="text">广播电视节目制作经营许可证</div>
                    </div>
                    <div class="swiper-slide my-swiper-slide">
                      <div class="date-item" style="padding-left: 120px">
                        <img
                          src="https://static.drlianzhuren.com/HairTransplant/CloudNumber/9070.png"
                          alt=""
                          style="width: 131px"
                        />
                      </div>
                      <div class="text">
                        【ISO9001认证】、【ISO/IEC 20000-1认证】
                      </div>
                    </div>
                    <div class="swiper-slide my-swiper-slide">
                      <div class="date-item" style="padding-left: 45px">
                        <img
                          src="https://static.drlianzhuren.com/HairTransplant/CloudNumber/9071.png"
                          alt=""
                          style="width: 280px"
                        />
                      </div>
                      <div class="text">50+软件著作权</div>
                    </div>
                    <div class="swiper-slide my-swiper-slide">
                      <div class="date-item" style="padding-left: 120px">
                        <img
                          src="../assets/software/12.jpg"
                          alt=""
                          style="width: 131px"
                        />
                      </div>
                      <div class="text">发明专利</div>
                    </div>
                  </div>
                  <img
                    class="fixed-prev"
                    src="https://static.drlianzhuren.com/HairTransplant/right.png"
                    @click="noisMilepost"
                    alt=""
                  />
                  <img
                    class="fixed-next"
                    src="https://static.drlianzhuren.com/HairTransplant/left.png"
                    @click="addisMilepost"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box" style="position: relative; margin-bottom: 80px">
        <div id="culture" style="position: absolute; top: -80px"></div>
        <div class="All-title wow fadeInUp" data-wow-delay="0.1s">
          <div class="cnmmon-text">
            企业文化
            <div class="cnmmon-line"></div>
          </div>
        </div>
        <div class="wow fadeInUp" data-wow-delay="0.2s">
          <ul class="m2cm2ul box clearfix">
            <li style="flex: 1">
              <a href="javascript:;" class="m2cm2a scaleImg">
                <div class="overflowHide">
                  <div class="bgImg bgImg1"></div>
                  <div class="m2cmc2lay">
                    <strong>愿景</strong>
                    <p class="m2cmc2p1" style="display: block">vision</p>
                    <p class="m2cmc2p2" style="display: none">
                      成为智慧健康服务的创领者！
                    </p>
                  </div>
                </div>
              </a>
            </li>
            <li style="flex: 1">
              <a href="javascript:;" class="m2cm2a scaleImg">
                <div class="overflowHide">
                  <div class="bgImg bgImg2"></div>
                  <div class="m2cmc2lay">
                    <strong>使命</strong>
                    <p class="m2cmc2p1" style="display: block">mission</p>
                    <div class="m2cmc2p2" style="display: none">
                      <div style="line-height: 28px">
                        以数智医疗服务，助力医疗与
                      </div>
                      <div style="line-height: 28px">
                        大健康行业高质量发展。
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <li style="flex: 1">
              <a href="javascript:;" class="m2cm2a scaleImg">
                <div class="overflowHide">
                  <div class="bgImg bgImg3"></div>
                  <div class="m2cmc2lay">
                    <strong>价值观</strong>
                    <p class="m2cmc2p1" style="display: block">
                      sense of worth
                    </p>
                    <div class="m2cmc2p2" style="display: none">
                      <div style="line-height: 28px">客户第一、至诚守信</div>
                      <div style="line-height: 28px">务实进取、责任担当</div>
                      <div style="line-height: 28px">创新求变、高效执行</div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Footer @currentChange="currentChange"></Footer>
  </div>
</template>

<script>
import Swiper from "swiper";
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
  components: { Header, Footer },
  methods: {
    currentChange(a) {
      document.querySelector(`#${a}`).scrollIntoView(true);
    },
    addisMilepost() {
      if (this.isMilepost == 8) {
        return;
      }
      this.isMilepost++;
      this.mySwiper.slideTo(this.isMilepost);
    },
    noisMilepost() {
      if (this.isMilepost == 0) {
        return;
      }
      this.isMilepost--;
      this.mySwiper.slideTo(this.isMilepost);
    },

    initSwiper() {
      let that = this;

      this.mySwiper = new Swiper(".swiper-box", {
        direction: "horizontal",

        slidesPerView: 3,
        loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //使用分页器
        on: {
          slideChangeTransitionStart: function () {
            that.isMilepost = this.activeIndex;
          },
        },
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: false,
      });
    },
  },
  data() {
    return {
      wow: null,
      isMilepost: 0,
      mySwiper: null,
    };
  },
  beforeDestroy() {
    this.wow.stop();
  },
  mounted() {
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
    this.initSwiper();
    $(".m2cm2a").hover(
      function () {
        $(this).find(".m2cmc2p1").slideUp(300);
        $(this).find(".m2cmc2p2").slideDown(300);
      },
      function () {
        $(this).find(".m2cmc2p2").slideUp(300);
        $(this).find(".m2cmc2p1").slideDown(300);
      }
    );
    if (this.$route.params.id) {
      document.querySelector(`#${this.$route.params.id}`).scrollIntoView(true);
    }
  },
};
</script>

<style lang="scss">
.m2cmCon2 {
  overflow: hidden;
}

.m2cm2ul {
  display: flex;
  justify-content: center;
  margin: 50px auto 0;
  transform: translate(-1%, 0);
}
.m2cm2ul li {
  width: 33.33%;
  float: left;
  position: relative;
}

.m2cm2a {
  display: block;
  cursor: default;
}

.m2cm2a .overflowHide {
  height: 260px;
}

.m2cmc2lay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.m2cmc2lay strong {
  font-size: 26px;
  letter-spacing: 2px;
  display: block;
  line-height: 40px;
  font-weight: normal;
}

.m2cmc2p1 {
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
  margin-top: 15px;
}

.m2cmc2p2 {
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 1px;
  margin-top: 15px;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

.m2cm2a .bgImg:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #2c84be;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.m2cm2a:hover .bgImg:after {
  opacity: 0.8;
}

.scaleImg .bgImg img {
  transition: all 0.5s ease-in-out;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}

.scaleImg:hover img {
  transform: scale(1.05);
}

.m2cmCon3 {
  padding: 5.2vw 0px;
}

.m2cmc3t {
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  letter-spacing: 2px;
  display: block;
  text-align: center;
}

.m2cmc3ul {
  margin: 1.56vw -20px 0px;
}

.m2cm3itm {
  padding: 20px;
}

.m2cmc3a {
  display: block;
}

.m2ryDx img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 70%;
  max-height: 70%;
  width: auto;
  height: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.m2cmc3Btn a {
  font-size: 16px;
  line-height: 48px;
  letter-spacing: 2px;
  text-align: center;
  display: inline-block;
  height: 50px;
  width: 150px;
  border: 1px solid #0e71e9;
  border-radius: 0.3em;
  color: #0e71e9;
}

.m2cmc3Btn a:hover {
  color: #fff;
  background: #2c84be;
  box-shadow: 0 3px 8px rgba(14, 113, 233, 0.3);
  transform: translateY(-5px);
}

.m2cm4t {
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  letter-spacing: 2px;
  display: block;
  text-align: center;
}

.m2cm4Swiper {
  margin-top: 3.12vw;
  position: relative;
  top: -2vw;
}

.m2cm4itm {
  height: 480px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  line-height: 40px;
  padding-left: 0px;
  text-align: center;
}

.m2cm4c2itm {
  padding-top: 90px;
  text-align: center;
  position: relative;
  height: 130px;
  cursor: pointer;
}

.m2cm4c2itm p {
  line-height: 40px;
  font-size: 18px;
  color: #1e2835;
}

.slick-current.m2cm4c2itm p {
  font-weight: bold;
  color: #086ee8;
}

.slick-current.m2cm4c2itm:after {
  opacity: 1;
}

.bgImg1 {
  background: url("https://static.drlianzhuren.com/HairTransplant/CloudNumber/9001.png")
    no-repeat;
  background-size: 100% 100%;
  height: 260px;
}

.bgImg2 {
  background: url("https://static.drlianzhuren.com/HairTransplant/CloudNumber/9002.png")
    no-repeat;
  background-size: 100% 100%;
  height: 260px;
}

.bgImg3 {
  background: url("https://static.drlianzhuren.com/HairTransplant/CloudNumber/9003.png")
    no-repeat;
  background-size: 100% 100%;
  height: 260px;
}
.advantagesBg {
  overflow: hidden;
  background: url("https://static.drlianzhuren.com/HairTransplant/CloudNumber/home/46.png")
    no-repeat;
  background-size: cover;
}
</style>
