<template>
  <div class="headerBg">
    <div class="header box">
      <img src="../assets/home/logo.png" alt="" class="header-logo" />
      <ul class="header-tab">
        <li
          @click="
            selectTab = 0;
            $router.push('/home');
          "
          :class="{ selectTab: selectTab == 0 }"
          @mouseenter="left = 2.5"
          @mouseleave="leaveTab"
        >
          首页
        </li>
        <li
          @click="
            selectTab = 1;
            $router.push('/software');
          "
          :class="{ selectTab: selectTab == 1 }"
          @mouseenter="left = 14.8"
          @mouseleave="leaveTab"
        >
          医疗软件开发
        </li>
        <li
          @click="
            selectTab = 2;
            $router.push('/wisdom');
          "
          :class="{ selectTab: selectTab == 2 }"
          @mouseenter="left = 31.5"
          @mouseleave="leaveTab"
        >
          物联网智慧医疗
        </li>
        <li
          @click="
            selectTab = 3;
            $router.push('/retail');
          "
          :class="{ selectTab: selectTab == 3 }"
          @mouseenter="left = 46.5"
          @mouseleave="leaveTab"
        >
          医药新零售
        </li>
        <li
          @click="
            selectTab = 4;
            $router.push('/detection');
          "
          class="SolutionTab"
          :class="{ selectTab: selectTab == 4 }"
          @mouseenter="left = 59.5"
          @mouseleave="leaveTab"
        >
          解决方案
          <div class="Solution">
            <div @click="$router.push('/software')">
              <div><img :src="`${$img}/CloudNumber/home/40.png`" alt="" /></div>
              <div>医疗软件开发</div>
            </div>
            <div @click="$router.push('/wisdom')">
              <div><img :src="`${$img}/CloudNumber/home/41.png`" alt="" /></div>
              <div>物联网智慧医疗</div>
            </div>
            <div @click="$router.push('/retail')">
              <div><img :src="`${$img}/CloudNumber/home/42.png`" alt="" /></div>
              <div>医药新零售</div>
            </div>
            <div @click="$router.push('/detection')">
              <div><img :src="`${$img}/CloudNumber/home/43.png`" alt="" /></div>
              <div>互联网医疗智慧服务</div>
            </div>
          </div>
        </li>
        <div class="Smegma"></div>
        <li
          @click="
            selectTab = 5;
            $router.push('/news');
          "
          :class="{ selectTab: selectTab == 5 }"
          @mouseenter="left = 71.3"
          @mouseleave="leaveTab"
        >
          新闻资讯
        </li>
        <li
          @click="
            selectTab = 6;
            $router.push('/aboutUs');
          "
          :class="{ selectTab: selectTab == 6 }"
          @mouseenter="left = 83.4"
          @mouseleave="leaveTab"
        >
          关于我们
        </li>
        <li
          @click="
            selectTab = 7;
            $router.push('/contactUs');
          "
          :class="{ selectTab: selectTab == 7 }"
          @mouseenter="left = 95.2"
          @mouseleave="leaveTab"
        >
          联系我们
        </li>
        <div class="buoy" :style="{ left: left + '%' }">
          <div class="sds"></div>
        </div>
      </ul>
    </div>
    <div class="suspendedTab">
      <div style="position: relative">
        <div class="suspended">
          <div class="suspendedBox suspendedBox1" style="top: 0">
            <div class="hoverPhone">400-681-0626</div>
            <div>
              <img class="item-img1" src="../assets/fixed/1.png" alt="" />
              <img class="item-img2" src="../assets/fixed/5.png" alt="" />
              <div>热线</div>
            </div>
          </div>
        </div>
        <div class="suspended">
          <div class="suspendedLeft">
            <img src="../assets/fixed/9.png" alt="" class="suspended-images" />
          </div>
          <div class="suspendedBox" style="top: 63px">
            <div>
              <img class="item-img1" src="../assets/fixed/2.png" alt="" />
              <img class="item-img2" src="../assets/fixed/6.png" alt="" />
              <div>公众号</div>
            </div>
          </div>
        </div>
        <div class="suspended">
          <div class="suspendedLeft">
            <img src="../assets/fixed/10.png" alt="" class="suspended-images" />
          </div>
          <div class="suspendedBox" style="top: 128px">
            <div>
              <img class="item-img1" src="../assets/fixed/3.png" alt="" />
              <img class="item-img2" src="../assets/fixed/7.png" alt="" />
              <div>企业微信</div>
            </div>
          </div>
        </div>
        <div class="suspended suspended4">
          <div class="suspendedBox" style="top: 192px" @click="goToTop">
            <img class="item-img1" src="../assets/fixed/4.png" alt="" />
            <img class="item-img2" src="../assets/fixed/8.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    section: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      left: 0.2,
      selectTab: 0,
      isShow: false,
    };
  },
  mounted() {
    switch (this.$route.path) {
      case "/home":
        this.selectTab = 0;
        break;
      case "/software":
        this.selectTab = 1;
        break;
      case "/wisdom":
        this.selectTab = 2;
        break;
      case "/retail":
        this.selectTab = 3;
        break;
      case "/news":
        this.selectTab = 5;
        break;
      case "/aboutUs":
        this.selectTab = 6;
        break;
      case "/contactUs":
        this.selectTab = 7;
        break;
      case "/info":
        this.selectTab = 5;
        break;
      case "/detection":
        this.selectTab = 4;
        break;
      case "/details":
        this.selectTab = 3;
        break;
    }
    this.leaveTab();
    if (this.$route.name !== "home") {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    goToTop() {
      if (this.$route.name == "home") {
        this.$emit("currentChange", 0);
      } else {
        document.documentElement.scrollTop = 0;
      }
    },
    getScroll() {
      return {
        top:
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0,
      };
    },
    getClientHeight() {
      var clientHeight = 0;
      if (document.body.clientHeight && document.documentElement.clientHeight) {
        var clientHeight =
          document.body.clientHeight < document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight;
      } else {
        var clientHeight =
          document.body.clientHeight > document.documentElement.clientHeight
            ? document.body.clientHeight
            : document.documentElement.clientHeight;
      }
      return clientHeight * 0.5;
    },
    handleScroll() {
      const scrollTop = this.getScroll().top;
      const getClientHeight = this.getClientHeight();
      if (scrollTop > getClientHeight) {
        if (document.querySelector(".suspended4").style.display !== "block") {
          document.querySelector(".suspended4").style.display = "block";
        }
      } else {
        if (document.querySelector(".suspended4").style.display !== "none") {
          document.querySelector(".suspended4").style.display = "none";
        }
      }
    },
    leaveTab() {
      switch (this.selectTab) {
        case 0:
          this.left = 2.5;
          break;

        case 1:
          this.left = 14.8;
          break;
        case 2:
          this.left = 31.5;
          break;
        case 3:
          this.left = 46.5;
          break;
        case 4:
          this.left = 59.5;
          break;
        case 5:
          this.left = 71.3;
          break;
        case 6:
          this.left = 83.4;
          break;
        case 7:
          this.left = 95.3;
          break;
      }
    },
  },
  watch: {
    section(newVal, oldVal) {
      if (newVal == 1) {
        document.querySelector(".suspended4").style.display = "none";
      } else {
        document.querySelector(".suspended4").style.display = "block";
      }
    },
  },
  beforeDestroy() {
    if (this.$route.name !== "home") {
      window.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style lang="scss">
.headerBg {
  z-index: 9999 !important;
}
.suspendedTab {
  display: flex;
  top: 65vh;
  position: fixed;
  z-index: 9999999999999;
  right: 20px;
}

.suspendedBox {
  text-align: center;
  width: 56px;
  height: 56px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 8px 39px 11px rgba(59, 59, 60, 0.04);
  opacity: 1;
  text-align: center;
  position: absolute;
  right: 0;
  font-weight: 400;
  color: #999999;
  line-height: 0px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.suspended img {
  width: 24px;
}

.suspendedLeft {
  background-color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  position: relative;
  right: -20px;
  top: 0;
  margin-top: -20px;
  display: none;
  text-align: center;
  img {
    width: 90px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.suspended {
  width: 200px;
}

.suspended:hover .suspendedLeft {
  display: block;
}
.suspended:hover .suspendedBox {
  color: #0660d5;
}
.suspended:nth-child(2):hover .suspendedLeft {
  top: 63px;
  display: block;
}

.suspended:nth-child(3):hover .suspendedLeft {
  top: 128px;
  display: block;
}

.suspended:hover .item-img1 {
  display: none;
}

.suspended:hover .item-img2 {
  width: 24px;
  display: inline-block;
}
.codeText {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
}

.item-img2 {
  display: none;
}

.Solution {
  width: 222px;
  height: 244px;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  display: none;
  overflow: hidden;
  > div {
    padding: 11px 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    > div:nth-child(1) {
      width: 36px;
      height: 36px;
      line-height: 42px;
      background: #e5eef8;
      border-radius: 50%;
      margin-left: 20px;
      margin-right: 8px;
      img {
        width: 18px;
      }
    }
  }
}

.SolutionTab {
  position: relative;
  //   height: 100px;
  //   line-height: 100px;
}
.SolutionTab:hover .Solution {
  position: absolute;
  top: 80px;
  display: block;
  width: 222px;
  height: 244px;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  text-align: center;
  background-color: #ffffff;
  font-size: 14px;
  left: 50%;

  z-index: 99;
  transform: translateX(-50%);
  > div {
    line-height: 30px;
    color: #444;
    font-weight: 400;
  }
  > div:hover {
    background: rgb(242, 242, 242);
  }
  padding: 10px 0;
}

.suspendedBox1 {
  display: flex;
  transition: all 0.5s ease;
}
.suspendedBox1:hover {
  width: 204px;
  height: 56px;
  background: linear-gradient(270deg, #0660d5 0%, #6db8f2 100%);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  overflow: hidden;
  color: #ffffff !important;
  .hoverPhone {
    display: block;
    left: 0;
    opacity: 1;
    animation: aniOpacity 0.5s linear both;
  }
  > div:nth-child(2) {
    position: absolute;
    right: 0;
    width: 56px;
  }
}
.hoverPhone {
  opacity: 0;
  position: absolute;
  left: 0;
  width: 148px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 56px;
  display: none;
}

@keyframes aniOpacity {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.SolutionTab:hover + .Smegma {
  opacity: 1;
  visibility: visible;
}
.suspended4 {
  display: none;
}
</style>
