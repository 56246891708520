<template>
  <div class="footer">
    <div class="box" style="display: flex; justify-content: space-between">
      <div class="footer-item">
        <div class="footer-title" @click="$router.push('/home')">首页</div>
        <div class="footer-text" @click="goToRouter('home', 1)">产品与服务</div>
        <div class="footer-text" @click="goToRouter('home', 2)">
          为什么选择云数智康
        </div>
        <div class="footer-text" @click="goToRouter('home', 3)">技术优势</div>
        <div class="footer-text" @click="goToRouter('home', 4)">新闻动态</div>
        <div class="footer-text" @click="goToRouter('home', 5)">合作伙伴</div>
      </div>
      <div class="footer-item">
        <div class="footer-title" @click="$router.push('/software')">
          医疗软件开发
        </div>
        <div class="footer-text" @click="goToRouter('software', 'software')">
          产品服务
        </div>
        <div class="footer-text" @click="goToRouter('software', 'Ecology')">
          产品生态
        </div>
        <div class="footer-text" @click="goToRouter('software', 'Scenario')">
          应用场景
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-title" @click="$router.push('/wisdom')">
          物联网应用开发
        </div>
        <div class="footer-text" @click="goToRouter('wisdom', 'Product')">
          产品优势
        </div>
        <div
          class="footer-text"
          @click="goToRouter('wisdom', 'technicalAdvan')"
        >
          技术优势
        </div>
        <div class="footer-text" @click="goToRouter('wisdom', 'Value')">
          方案价值
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-title" @click="$router.push('/retail')">
          医疗新零售
        </div>
        <div
          class="footer-text"
          @click="goToRouter('retail', 'retailAdvantages')"
        >
          产品优势
        </div>
        <div class="footer-text" @click="goToRouter('retail', 'empowerment')">
          产品赋能
        </div>
        <div
          class="footer-text"
          @click="goToRouter('retail', 'medicalDevices')"
        >
          医疗器械与产品
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-title" @click="$router.push('/detection')">
          解决方案
        </div>
        <div class="footer-text" @click="$router.push('/software')">
          医疗软件开发
        </div>
        <div class="footer-text" @click="$router.push('/wisdom')">
          物联网应用开发
        </div>
        <div class="footer-text" @click="$router.push('/retail')">
          医疗新零售
        </div>
        <div class="footer-text" @click="$router.push('/detection')">
          互联网医疗智慧服务
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-title" @click="$router.push('/news')">新闻资讯</div>
        <div class="footer-text" @click="goToRouter('news', 1)">企业动态</div>
      </div>
      <div class="footer-item">
        <div class="footer-title" @click="$router.push('/aboutUs')">
          关于我们
        </div>
        <div class="footer-text" @click="goToRouter('aboutUs', 'Company')">
          企业简介
        </div>
        <div
          class="footer-text"
          @click="goToRouter('aboutUs', 'aboutUsAdvantages')"
        >
          核心优势
        </div>
        <div
          class="footer-text"
          @click="goToRouter('aboutUs', 'aboutUsMilepost')"
        >
          荣誉资质
        </div>
        <div class="footer-text" @click="goToRouter('aboutUs', 'culture')">
          企业文化
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-title" @click="$router.push('/contactUs')">
          联系我们
        </div>
        <div class="footer-text">联系电话： 400-681-0626</div>
        <div class="footer-text">地址：北京市丰台区广安路1幢-1层</div>
        <div style="display: flex">
          <div class="footer-code">
            <div style="background: #ffffff; padding: 4px 5px 3px 5px">
              <img
                src="https://static.drlianzhuren.com/brandWeb/contactUs/official-account.jpg?v=1"
                alt=""
              />
            </div>
            <div class="Code-text">微信公众号</div>
          </div>
          <div class="footer-code">
            <div style="background: #ffffff; padding: 5px 5px 3px 5px">
              <img src="../assets/fixed/10.png" alt="" />
            </div>
            <div class="Code-text">企业微信</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box filings">
      Copyright © www.yszkmedical.com, All Rights Reserved. 云数智康
      <a style="color: inherit" href="http://beian.miit.gov.cn" target="_blank"
        >京ICP备2023006764号-1</a
      >
      <img
        :src="`${$url}/%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87.png`"
        alt=""
        style="vertical-align: bottom; margin: -3px 5px; margin-right: 2px"
      />
      <a
        target="_blank"
        style="color: inherit"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011502006571"
      >
        京公网安备 11011502006571号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToRouter(a, b) {
      if (this.$route.name == a) {
        this.$emit("currentChange", b);
      }
      this.$router.push({ name: a, params: { id: b } });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.footer-item {
  > div {
    cursor: pointer;
  }
}
</style>
