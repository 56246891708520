<template>
  <div>
    <div class="title">医院高质量发展需要怎样的HIS系统？</div>
    <div class="date">2022-11-09</div>
    <div class="line"></div>
    <div class="m3nLtop">
      <div class="m3nContent">
        <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); visibility: visible; overflow-wrap: break-word !important;">
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;">
            <p>同样使用<span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9; visibility: visible;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;">HIS系统</strong></span>，为什么其他医院能提升医院效益，而你的医院却毫无起色？</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;"></span></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;">来看看你有没有遇到过以下情况</span><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;">：</span></p>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px 0px -10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: left; justify-content: flex-start; display: flex; flex-flow: row nowrap; visibility: visible;">
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: top; width: 338.5px; align-self: flex-start; flex: 0 0 auto; visibility: visible;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: perspective(0px); transform-style: flat; visibility: visible;">
                <section style="margin: 10px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: right; justify-content: flex-end; display: flex; flex-flow: row nowrap; transform: translate3d(31px, 0px, 0px) rotateX(180deg) rotateY(180deg); visibility: visible;">
                  <section style="margin: 0px 3px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: top; width: auto; align-self: flex-start; flex: 0 0 0%; height: auto; visibility: visible;">
                    <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: rotateZ(8.51deg); visibility: visible;">
                      <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: center; visibility: visible;">
                        <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; display: inline-block; width: 4px; height: 17px; vertical-align: top; overflow: hidden; border-style: solid; border-width: 1px; border-color: rgb(11, 119, 217); visibility: visible; overflow-wrap: break-word !important;">
                          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: justify; visibility: visible;">
                            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;"></p>
                          </section>
                        </section>
                      </section>
                    </section>
                  </section>
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: top; width: auto; align-self: flex-start; flex: 0 0 0%; height: auto; visibility: visible;">
                    <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: rotateZ(8.51deg); visibility: visible;">
                      <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: center; visibility: visible;">
                        <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; display: inline-block; width: 4px; height: 17px; vertical-align: top; overflow: hidden; border-style: solid; border-width: 1px; border-color: rgb(11, 119, 217); visibility: visible; overflow-wrap: break-word !important;">
                          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: justify; visibility: visible;">
                            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;"></p>
                          </section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: top; width: 338.5px; visibility: visible;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; height: auto; transform: perspective(0px); transform-style: flat; visibility: visible;">
                <section style="margin: 10px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; justify-content: flex-start; display: flex; flex-flow: row nowrap; transform: translate3d(-27px, 0px, 0px) rotateX(180deg) rotateY(180deg); visibility: visible;">
                  <section style="margin: 0px 3px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: top; width: auto; align-self: flex-start; flex: 0 0 0%; height: auto; visibility: visible;">
                    <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: rotateZ(8.51deg); visibility: visible;">
                      <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: center; visibility: visible;">
                        <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; display: inline-block; width: 4px; height: 17px; vertical-align: top; overflow: hidden; border-style: solid; border-width: 1px; border-color: rgb(11, 119, 217); visibility: visible; overflow-wrap: break-word !important;">
                          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: justify; visibility: visible;">
                            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;"></p>
                          </section>
                        </section>
                      </section>
                    </section>
                  </section>
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: top; width: auto; align-self: flex-start; flex: 0 0 0%; height: auto; visibility: visible;">
                    <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: rotateZ(8.51deg); visibility: visible;">
                      <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: center; visibility: visible;">
                        <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; display: inline-block; width: 4px; height: 17px; vertical-align: top; overflow: hidden; border-style: solid; border-width: 1px; border-color: rgb(11, 119, 217); visibility: visible; overflow-wrap: break-word !important;">
                          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: justify; visibility: visible;">
                            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;"></p>
                          </section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px 0px 10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: left; justify-content: flex-start; display: flex; flex-flow: row nowrap; visibility: visible;">
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; width: 677px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; border-style: solid; border-width: 1px; border-color: rgb(11, 119, 217); visibility: visible;">
              <section powered-by="xiumi.us" style="margin: 0px 0px 5px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; justify-content: flex-start; display: flex; flex-flow: row nowrap; visibility: visible;">
                <section style="margin: 0px; padding: 28px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; width: 675px; vertical-align: top; align-self: flex-start; flex: 0 0 auto; border-bottom: 1px solid rgb(11, 119, 217); border-bottom-right-radius: 0px; visibility: visible;">
                  <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: justify; visibility: visible;">
                    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;">·&nbsp;</strong><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9; visibility: visible;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;">跟不上：</strong></span>随着医改推进，取消药品、耗材加成，DRG/DIP推广落地，现有HIS系统已经不能适应；</p>
                    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;">·&nbsp;</strong><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9; visibility: visible;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;">处理慢：</strong></span>从数字化到数智化转变中，HIS系统数据统计结果不一致、统计结果无法直接使用、数据统计需求处理慢。受到掣肘，精细化运营和医疗质控工作难以开展；</p>
                    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;">·&nbsp;</strong><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9; visibility: visible;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;">不实用：</strong></span>集团化区域化趋势下，医院业务范围不断拓展，要求数据跨院区、跨区域共享，但现有HIS系统不支持跨系统数据统计，阻碍高速发展……<br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;"></p>
                  </section>
                </section>
              </section>
            </section>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;">
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important; visibility: visible;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;">遇到这些情况，或者担心选错、用不好，不要慌！今天我们来探讨下——<span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9; visibility: visible;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;">医院高质量发展究竟需要什么样的HIS系统。</strong></span></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;"></p>
          </section>
          <section powered-by="xiumi.us" style="margin: 15px 0px 10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: center; justify-content: center; transform: translate3d(-3px, 0px, 0px); display: flex; flex-flow: row nowrap; visibility: visible;">
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; display: inline-block; width: auto; vertical-align: middle; align-self: center; min-width: 10%; flex: 0 0 auto; height: auto; border-style: solid; border-width: 1px; border-color: rgb(102, 102, 102); box-shadow: rgb(255, 218, 92) 5px 5px 0px; visibility: visible; overflow-wrap: break-word !important;">
              <section powered-by="xiumi.us" style="margin: -7px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: right; justify-content: flex-end; transform: translate3d(-12px, 0px, 0px); display: flex; flex-flow: row nowrap; visibility: visible;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; width: 33px; vertical-align: middle; box-shadow: rgb(0, 0, 0) 0px 0px 0px; flex: 0 0 auto; height: auto; align-self: center; line-height: 0; background-color: rgb(254, 255, 255); border-width: 0px; visibility: visible;">
                  <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: rotateZ(37deg); visibility: visible;">
                    <section style="margin: 2px 0px -3px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: left; transform: translate3d(8px, 0px, 0px); visibility: visible;">
                      <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; width: 4px; height: 18px; vertical-align: top; overflow: hidden; background-color: rgb(11, 119, 217); border-width: 0px; border-radius: 16px; border-style: none; border-color: rgb(62, 62, 62); box-shadow: rgb(74, 187, 168) 9px -6px 0px; visibility: visible;"><svg viewBox="0 0 1 1" style="float: left; line-height: 0; width: 0px; vertical-align: top; visibility: visible;"></svg></section>
                    </section>
                  </section>
                </section>
              </section>
              <section powered-by="xiumi.us" style="margin: 0px 0px 4px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: left; visibility: visible;">
                <section style="margin: 0px; padding: 0px 20px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; font-size: 18px; text-align: center; color: rgb(11, 119, 217); line-height: 2; visibility: visible;">
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;">政策指路</strong></p>
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; visibility: visible; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; visibility: visible;">HIS系统建设要点和指标</strong></p>
                </section>
              </section>
            </section>
          </section>
          <p powered-by="xiumi.us" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">围绕着《公立医院高质量发展的意见》要求，今年7月，国家卫健委发布了<strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9;">《公立医院高质量发展评价指标（试行）》</span></strong>，从专科能力、医疗质量、医疗效率等方向，为医院全面落实高质量发展提出核心要求。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; text-align: center; box-sizing: border-box !important; overflow-wrap: break-word !important;"><img src="https://static.drlianzhuren.com/HairTransplant/CloudNumber/news/4/1.png"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">有实施意见，有考核指标，高质量发展已然成为医院下一阶段改革的发展核心。顺应发展需求，医院也需要深化内部改革，调整管理结构，以“高效运行”促“高质量发展”。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
          </section>
          <section powered-by="xiumi.us" style="margin: 10px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: left; justify-content: flex-start; display: flex; flex-flow: row nowrap;">
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; min-width: 10%; flex: 0 0 auto; height: auto; align-self: flex-end;">
              <section powered-by="xiumi.us" style="margin: -6px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: scale(0.7); transform-origin: center center;">
                <section style="margin: 0px 0px 26px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; font-size: 19px; text-align: center;">
                  <section style="margin: 0px auto; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; border-width: 1px; border-style: solid; border-color: rgb(11, 119, 217); background-color: rgb(11, 119, 217); width: 1.8em; height: 1.8em; line-height: 1.8em; border-radius: 100%; font-size: 22px; color: rgb(255, 255, 255);">
                    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">1</strong></p>
                  </section>
                </section>
              </section>
            </section>
            <section style="margin: 0px 0px 0px -14px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; min-width: 10%; flex: 0 0 auto; height: auto; align-self: flex-end;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: rotateZ(37deg);">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: translate3d(-1px, 0px, 0px);">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; display: inline-block; width: 28px; height: 57px; vertical-align: top; overflow: hidden; border-left: 1px solid rgb(11, 119, 217); border-bottom-left-radius: 0px; overflow-wrap: break-word !important;"><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                </section>
              </section>
            </section>
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: middle; width: auto; align-self: center; min-width: 10%; flex: 0 0 auto; height: auto;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: translate3d(-13px, 0px, 0px);">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(11, 119, 217); text-align: justify;">
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">加强专科能力</strong></p>
                </section>
              </section>
            </section>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">提升专科服务能力，是医院在高质量发展阶段的关键任务。《评价指标》中专科能力指数按疾病病种分类，将病种例数、四级手术占比、微创手术占比、平均住院日、次均费用等相关参数纳入专科能力考核体系。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">相对应，好的HIS系统功能及页面，也必须要适应医院<strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9;">特色诊疗流程</span></strong>、具备多维度的<span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">数据分析能力</strong></span>，支持医院做好<strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9;">精细化医疗质控</span></strong>。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
          </section>
          <section powered-by="xiumi.us" style="margin: 10px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: left; justify-content: flex-start; display: flex; flex-flow: row nowrap;">
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; min-width: 10%; flex: 0 0 auto; height: auto; align-self: flex-end;">
              <section powered-by="xiumi.us" style="margin: -6px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: scale(0.7); transform-origin: center center;">
                <section style="margin: 0px 0px 26px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; font-size: 19px; text-align: center;">
                  <section style="margin: 0px auto; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; border-width: 1px; border-style: solid; border-color: rgb(11, 119, 217); background-color: rgb(11, 119, 217); width: 1.8em; height: 1.8em; line-height: 1.8em; border-radius: 100%; font-size: 22px; color: rgb(255, 255, 255);">
                    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">2<br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
                  </section>
                </section>
              </section>
            </section>
            <section style="margin: 0px 0px 0px -14px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; min-width: 10%; flex: 0 0 auto; height: auto; align-self: flex-end;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: rotateZ(37deg);">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: translate3d(-1px, 0px, 0px);">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; display: inline-block; width: 28px; height: 57px; vertical-align: top; overflow: hidden; border-left: 1px solid rgb(11, 119, 217); border-bottom-left-radius: 0px; overflow-wrap: break-word !important;"><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                </section>
              </section>
            </section>
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: middle; width: auto; align-self: center; min-width: 10%; flex: 0 0 auto; height: auto;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: translate3d(-13px, 0px, 0px);">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(11, 119, 217); text-align: justify;">
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">强调医疗质量</strong></p>
                </section>
              </section>
            </section>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">医院强不强，关键看医疗质量。《评价指标》中的医疗质量指数紧扣择期手术并发症发生率、I类切口手术部位感染率、抗菌药物使用强度、低风险组死亡率、RW值与CMI值等关键指标，既着眼于手术技术规范，也着眼于日常诊疗管理。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">因此，其配套的HIS系统也需要兼具<strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9;">医疗质量的全程监控管理</span></strong>、<strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9;">数据治理能力</span></strong>，帮助医院跟上现代化医院质量管理发展的步伐。</p>
          </section>
          <p powered-by="xiumi.us" style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
          <section powered-by="xiumi.us" style="margin: 10px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: left; justify-content: flex-start; display: flex; flex-flow: row nowrap;">
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; min-width: 10%; flex: 0 0 auto; height: auto; align-self: flex-end;">
              <section powered-by="xiumi.us" style="margin: -6px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: scale(0.7); transform-origin: center center;">
                <section style="margin: 0px 0px 26px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; font-size: 19px; text-align: center;">
                  <section style="margin: 0px auto; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; border-width: 1px; border-style: solid; border-color: rgb(11, 119, 217); background-color: rgb(11, 119, 217); width: 1.8em; height: 1.8em; line-height: 1.8em; border-radius: 100%; font-size: 22px; color: rgb(255, 255, 255);">
                    <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">3</strong></p>
                  </section>
                </section>
              </section>
            </section>
            <section style="margin: 0px 0px 0px -14px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; min-width: 10%; flex: 0 0 auto; height: auto; align-self: flex-end;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: rotateZ(37deg);">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: translate3d(-1px, 0px, 0px);">
                  <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; display: inline-block; width: 28px; height: 57px; vertical-align: top; overflow: hidden; border-left: 1px solid rgb(11, 119, 217); border-bottom-left-radius: 0px; overflow-wrap: break-word !important;"><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                </section>
              </section>
            </section>
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: middle; width: auto; align-self: center; min-width: 10%; flex: 0 0 auto; height: auto;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: translate3d(-13px, 0px, 0px);">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(11, 119, 217); text-align: justify;">
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">主抓医疗效率</strong></p>
                </section>
              </section>
            </section>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">医院的职责不仅在于能看病，还要看好病，减少不必要的诊疗时间消耗与资源消耗。随着医联体的不断发展壮大，医疗机构集团化、规模化已经成为医疗机构新态势，需要增强大医院和中小医院社区医院的关联，增强中小社区医院的医学能力。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">在这样的新形式下，面对跨区域、跨业态管理难题，医院应该怎样快速实现垂直管理？只有借助强大的医疗信息平台驱动医院集团化模式，<strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9;">整合集团内部医疗资源</span></strong>，实现<span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">数据共享</strong></span><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><span style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: #0B77D9;">和高效协同</span></strong>。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
          </section>
          <section powered-by="xiumi.us" style="margin: 15px 0px 10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: center; justify-content: center; transform: translate3d(-3px, 0px, 0px); display: flex; flex-flow: row nowrap;">
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; display: inline-block; width: auto; vertical-align: middle; align-self: center; min-width: 10%; flex: 0 0 auto; height: auto; border-style: solid; border-width: 1px; border-color: rgb(102, 102, 102); box-shadow: rgb(255, 218, 92) 5px 5px 0px; overflow-wrap: break-word !important;">
              <section powered-by="xiumi.us" style="margin: -7px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: right; justify-content: flex-end; transform: translate3d(-12px, 0px, 0px); display: flex; flex-flow: row nowrap;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; width: 33px; vertical-align: middle; box-shadow: rgb(0, 0, 0) 0px 0px 0px; flex: 0 0 auto; height: auto; align-self: center; line-height: 0; background-color: rgb(254, 255, 255); border-width: 0px;">
                  <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; transform: rotateZ(37deg);">
                    <section style="margin: 2px 0px -3px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: left; transform: translate3d(8px, 0px, 0px);">
                      <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; width: 4px; height: 18px; vertical-align: top; overflow: hidden; background-color: rgb(11, 119, 217); border-width: 0px; border-radius: 16px; border-style: none; border-color: rgb(62, 62, 62); box-shadow: rgb(74, 187, 168) 9px -6px 0px;"><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                    </section>
                  </section>
                </section>
              </section>
              <section powered-by="xiumi.us" style="margin: 0px 0px 4px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; text-align: left;">
                <section style="margin: 0px; padding: 0px 20px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; font-size: 18px; text-align: center; color: rgb(11, 119, 217); line-height: 2;">
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">云数智康HIS系统</strong></p>
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">助力医院迈入高质量发展“快车道”</strong></p>
                </section>
              </section>
            </section>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">云数智康深耕医疗信息化行业十七年，专注于围绕每一位客户的独特需求，结合以客户为中心的业务流程、全闭环质控的管理、灵活化的数据架构、差异化运营等优势，务实地提供定制化解决方案。在“做深做精”中，不断积累丰富的经验和扎实的基础，成为了千家医疗机构的共同选择。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
          </section>
          <section powered-by="xiumi.us" style="margin: 10px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: flex; flex-flow: row nowrap; text-align: left; justify-content: flex-start;">
            <section style="margin: 0px -17px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 8 8 0%; border-bottom: 1px solid rgb(11, 119, 217); border-bottom-right-radius: 0px; height: auto; line-height: 1;"><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; border-width: 0px 0px 1px; border-style: none; border-color: rgb(62, 62, 62) rgb(62, 62, 62) rgb(181, 150, 238); overflow: hidden; flex: 13 13 0%; align-self: flex-end; height: auto; z-index: 1;">
              <section powered-by="xiumi.us" style="margin: 0px 0px -10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(255, 255, 255); font-size: 60px; line-height: 1; letter-spacing: 0px; text-align: center; text-shadow: rgb(11, 119, 217) 0px 1.4px, rgb(11, 119, 217) 1px 1px, rgb(11, 119, 217) 1.4px 0px, rgb(11, 119, 217) 1px -1px, rgb(11, 119, 217) 0px -1.4px, rgb(11, 119, 217) -1px -1px, rgb(11, 119, 217) -1.4px 0px, rgb(11, 119, 217) -1px 1px;">
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><em style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">1</strong></em></p>
                </section>
              </section>
            </section>
            <section style="margin: 0px 0px 0px -16px; padding: 5px 10px 5px 20px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; flex: 100 100 0%; align-self: flex-end; height: auto; border-bottom: 1px solid rgb(11, 119, 217); border-bottom-right-radius: 0px;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(11, 119, 217); text-align: justify;">
                <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">以客户为中心的诊疗流程</strong></p>
              </section>
            </section>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">提供基于账户模式的门诊就诊流程优化方案，充分利用分时段预约、实时信息通知、提前摆药、条码应用、预诊功能等手段，缩短患者无效就医时间，提供主动服务，优化就医环境，给患者提供高品质的个性化服务体验。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">全面支持医疗机构实现互联网服务，为患者提供一站式预约、支付、报告查询、远程诊疗、购药、满意度调查、健康关怀等服务。</p>
          </section>
          <p style="text-align:center"><img :src="`${$img}CloudNumber-m/article/4.png`"></p>
          <section powered-by="xiumi.us" style="margin: 10px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: flex; flex-flow: row nowrap; text-align: left; justify-content: flex-start;">
            <section style="margin: 0px -17px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 8 8 0%; border-bottom: 1px solid rgb(11, 119, 217); border-bottom-right-radius: 0px; height: auto; line-height: 1;"><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; border-width: 0px 0px 1px; border-style: none; border-color: rgb(62, 62, 62) rgb(62, 62, 62) rgb(181, 150, 238); overflow: hidden; flex: 13 13 0%; align-self: flex-end; height: auto; z-index: 1;">
              <section powered-by="xiumi.us" style="margin: 0px 0px -10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(255, 255, 255); font-size: 60px; line-height: 1; letter-spacing: 0px; text-align: center; text-shadow: rgb(11, 119, 217) 0px 1.4px, rgb(11, 119, 217) 1px 1px, rgb(11, 119, 217) 1.4px 0px, rgb(11, 119, 217) 1px -1px, rgb(11, 119, 217) 0px -1.4px, rgb(11, 119, 217) -1px -1px, rgb(11, 119, 217) -1.4px 0px, rgb(11, 119, 217) -1px 1px;">
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><em style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">2</strong></em></p>
                </section>
              </section>
            </section>
            <section style="margin: 0px 0px 0px -16px; padding: 5px 10px 5px 20px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; flex: 100 100 0%; align-self: flex-end; height: auto; border-bottom: 1px solid rgb(11, 119, 217); border-bottom-right-radius: 0px;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(11, 119, 217); text-align: justify;">
                <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">全闭环的精细化医疗管理</strong></p>
              </section>
            </section>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">为适应医疗机构的差异化诊疗流程，云数智康 HIS 搭建了完整医疗质控管理体系，严格规范医嘱、电子病历、临床路径、移动医护、影像检验、手术麻醉全流程，推动医疗机构从“被动式”末端质控，走向“主动式”过程质控，实现所有环节可追溯的精细化管理。</p>
          </section>
          <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; text-align: center; box-sizing: border-box !important; overflow-wrap: break-word !important;"><br></p>
          <section powered-by="xiumi.us" style="margin: 10px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: flex; flex-flow: row nowrap; text-align: left; justify-content: flex-start;">
            <section style="margin: 0px -17px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 8 8 0%; border-bottom: 1px solid rgb(11, 119, 217); border-bottom-right-radius: 0px; height: auto; line-height: 1;"><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; border-width: 0px 0px 1px; border-style: none; border-color: rgb(62, 62, 62) rgb(62, 62, 62) rgb(181, 150, 238); overflow: hidden; flex: 13 13 0%; align-self: flex-end; height: auto; z-index: 1;">
              <section powered-by="xiumi.us" style="margin: 0px 0px -10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(255, 255, 255); font-size: 60px; line-height: 1; letter-spacing: 0px; text-align: center; text-shadow: rgb(11, 119, 217) 0px 1.4px, rgb(11, 119, 217) 1px 1px, rgb(11, 119, 217) 1.4px 0px, rgb(11, 119, 217) 1px -1px, rgb(11, 119, 217) 0px -1.4px, rgb(11, 119, 217) -1px -1px, rgb(11, 119, 217) -1.4px 0px, rgb(11, 119, 217) -1px 1px;">
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><em style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">3</strong></em></p>
                </section>
              </section>
            </section>
            <section style="margin: 0px 0px 0px -16px; padding: 5px 10px 5px 20px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; flex: 100 100 0%; align-self: flex-end; height: auto; border-bottom: 1px solid rgb(11, 119, 217); border-bottom-right-radius: 0px;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(11, 119, 217); text-align: justify;">
                <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">支持集中部署信息共享</strong></p>
              </section>
            </section>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">云数智康 HIS 支持云端部署（公有云和私有云）模式，充分合理节省硬件服务器资源投入。优越的云端部署模式大大增强医院信息系统运行环境的稳定性、灵活性。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">支持多个医院进行统一集中部署管理，充分利用一个数据中心实现业务数据字典、患者数据、客户信息、产品管理、CDR ／ ODR 数据中心、资源共享中心等的统一管理，赋能医疗集团化规模化发展。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          </section>
          <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; text-align: center; box-sizing: border-box !important; overflow-wrap: break-word !important;">
            <img :src="`${$img}CloudNumber-m/article/5.png`">
          </p>
          <section powered-by="xiumi.us" style="margin: 10px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: flex; flex-flow: row nowrap; text-align: left; justify-content: flex-start;">
            <section style="margin: 0px -17px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 8 8 0%; border-bottom: 1px solid rgb(11, 119, 217); border-bottom-right-radius: 0px; height: auto; line-height: 1;"><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; border-width: 0px 0px 1px; border-style: none; border-color: rgb(62, 62, 62) rgb(62, 62, 62) rgb(181, 150, 238); overflow: hidden; flex: 13 13 0%; align-self: flex-end; height: auto; z-index: 1;">
              <section powered-by="xiumi.us" style="margin: 0px 0px -10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(255, 255, 255); font-size: 60px; line-height: 1; letter-spacing: 0px; text-align: center; text-shadow: rgb(11, 119, 217) 0px 1.4px, rgb(11, 119, 217) 1px 1px, rgb(11, 119, 217) 1.4px 0px, rgb(11, 119, 217) 1px -1px, rgb(11, 119, 217) 0px -1.4px, rgb(11, 119, 217) -1px -1px, rgb(11, 119, 217) -1.4px 0px, rgb(11, 119, 217) -1px 1px;">
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><em style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">4</strong></em></p>
                </section>
              </section>
            </section>
            <section style="margin: 0px 0px 0px -16px; padding: 5px 10px 5px 20px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; flex: 100 100 0%; align-self: flex-end; height: auto; border-bottom: 1px solid rgb(11, 119, 217); border-bottom-right-radius: 0px;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(11, 119, 217); text-align: justify;">
                <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">多维度数据分析平台</strong></p>
              </section>
            </section>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">云数智康数据分析平台根据面向对象不同，支持院领导驾驶舱、绩效管理驾驶舱、医务管理驾驶舱、财务收入监测驾驶舱等各种不同主题，支持用折线图、饼图、柱状图等不同样式图表 , 展现使用者关心的指标数值、变化趋势、排名等，并支持 PC 端与移动端多终端查看，方便使用者随时随地了解医院当前运营情况。</p>
          </section>
          <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; text-align: center; box-sizing: border-box !important; overflow-wrap: break-word !important;">
            <img :src="`${$img}CloudNumber-m/article/6.png`">
          </p>
          <section powered-by="xiumi.us" style="margin: 10px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: flex; flex-flow: row nowrap; text-align: left; justify-content: flex-start;">
            <section style="margin: 0px -17px 0px 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; align-self: flex-end; flex: 8 8 0%; border-bottom: 1px solid rgb(11, 119, 217); border-bottom-right-radius: 0px; height: auto; line-height: 1;"><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
            <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; border-width: 0px 0px 1px; border-style: none; border-color: rgb(62, 62, 62) rgb(62, 62, 62) rgb(181, 150, 238); overflow: hidden; flex: 13 13 0%; align-self: flex-end; height: auto; z-index: 1;">
              <section powered-by="xiumi.us" style="margin: 0px 0px -10px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
                <section style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(255, 255, 255); font-size: 60px; line-height: 1; letter-spacing: 0px; text-align: center; text-shadow: rgb(11, 119, 217) 0px 1.4px, rgb(11, 119, 217) 1px 1px, rgb(11, 119, 217) 1.4px 0px, rgb(11, 119, 217) 1px -1px, rgb(11, 119, 217) 0px -1.4px, rgb(11, 119, 217) -1px -1px, rgb(11, 119, 217) -1.4px 0px, rgb(11, 119, 217) -1px 1px;">
                  <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><em style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">5</strong></em></p>
                </section>
              </section>
            </section>
            <section style="margin: 0px 0px 0px -16px; padding: 5px 10px 5px 20px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; display: inline-block; vertical-align: bottom; width: auto; flex: 100 100 0%; align-self: flex-end; height: auto; border-bottom: 1px solid rgb(11, 119, 217); border-bottom-right-radius: 0px;">
              <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important; color: rgb(11, 119, 217); text-align: justify;">
                <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><strong style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">双引擎设计精细化医疗质控</strong></p>
              </section>
            </section>
          </section>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">为适应医疗机构根据不同业务形成的不同管理流程 , 云数智康 HIS 设计了基于流程引擎和规则引擎的双引擎系统。通过引擎系统的灵活配置，使得医疗机构可以灵活定义各种医疗流程和步骤，实现不同类型医嘱的差异化医疗流程和状态管理。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;"></p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">场景化、精细化、全闭环、可视化的医疗质量管理，全面提升医疗流程效率，保障医疗品质。</p>
            <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          </section>
          <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; text-align: center; box-sizing: border-box !important; overflow-wrap: break-word !important;">
            <img :src="`${$img}CloudNumber-m/article/7.png`">
          </p>
          <p style="margin-top: 0px; margin-bottom: 0px; padding: 0px; outline: 0px; max-width: 100%; clear: both; min-height: 1em; text-align: center; box-sizing: border-box !important; overflow-wrap: break-word !important;"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; overflow-wrap: break-word !important;">
            <p style="margin-top: 0px; margin-bottom: 50px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box; clear: both; min-height: 1em; overflow-wrap: break-word !important;">凭借专业技术及优质服务，云数智康已获得如莱佛士医疗集团、百汇医疗集团、哥伦比亚医疗集团、三博脑科医疗集团、复星医疗集团等业内数十家标杆集团客户的实践检验。利用上千家国内、国际项目的研发和搭建经验，以及专业高效的团队执行力，帮助医疗机构迈入高质量发展“快车道”。</p>
          </section>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>