<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    if (this._isMobile()) {
      location.href = "https://m.yszkmedical.com/home";
      console.log("手机端");
    } else {
      console.log("pc端");
    }
  },
  methods: {
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="scss">
body {
  font-family: "pingFangSC-Medium";
}
a {
  text-decoration: none;
}
</style>
