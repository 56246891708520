<template>
  <div>
    <Header></Header>
    <div class="retail">
      <div class="banner">
        <div class="banner-box2 box">
          <div class="banner-title wow fadeInUp">医药新零售</div>
          <div class="banner-detail wow fadeInUp">通过线上线下及公域私域相融合，提供导购数字化、营销数字化、会员数字化等赋能工具，帮助医药门店实现全时全域经营，多位一体数字化构建可持续智慧增长。</div>
        </div>
        <img :src="`${$img}CloudNumber/banner/7.png?v=1`" alt="" class="banner-img">
      </div>
      <div class="retailAdvantagesBg">
        <div class="box" style="position: relative;">
          <div id="retailAdvantages" style="position: absolute;top:-80px"></div>
          <div class="All-title  wow fadeInUp" data-wow-delay="0.1s">
            <div class="cnmmon-text">
              产品优势
              <div class="cnmmon-line"></div>
            </div>
          </div>
          <div class="advantages">
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.2s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/16.png`" alt="">
              </div>
              <div class="as-title">应用层</div>
              <div class="as-text">基于历史交付和实践经验所设计的应用模板，可帮助客户有效缩减应用的开发时间。</div>
            </div>
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.2s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/17.png`" alt="">
              </div>
              <div class="as-title">平台层</div>
              <div class="as-text">完善的可视化开发和管理功能，帮助客户轻松完成设备端的"管、控、营"一体化。</div>
            </div>
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.2s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/18.png`" alt="">
              </div>
              <div class="as-title">传输层</div>
              <div class="as-text">借助智能网关及SDK，帮助客户快速接入各类设备，让客户的数据更迅速、安全的流动。</div>
            </div>
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.3s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/19.png`" alt="">
              </div>
              <div class="as-title">感知层</div>
              <div class="as-text">广泛兼容标准协议，面对各种复杂的设备部署方案游刃有余, 帮助客户有效降低实施成本。</div>
            </div>
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.3s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/20.png`" alt="">
              </div>
              <div class="as-title">体验层</div>
              <div class="as-text">使用云数智康平台可以轻松的开发跨平台的物联网应用，以高度灵活的适配数据需求。</div>
            </div>
            <div class="advantages-item  wow fadeInUp" data-wow-delay="0.3s">
              <div class="as-top">
                <img :src="`${$img}/CloudNumber/home/21.png`" alt="">
              </div>
              <div class="as-title">开发层</div>
              <div class="as-text">告别各类工具的沉重束缚，使用桌面端，移动端访问, 有效降低开发运维的成本和复杂度。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="empowerment" style="position: relative;">
        <div id="empowerment" style="position: absolute;top:-80px"></div>
        <div class="box">
          <div class="All-title wow fadeInUp" data-wow-delay="0.1s">
            <div class="cnmmon-text">
              产品赋能
              <div class="cnmmon-line"></div>
            </div>
          </div>
        </div>
        <div class="empowerment-box box">
          <div class="empowerment-item   wow fadeInUp" data-wow-delay="0.2s">
            <div class="empowerment-top">
              <img :src="`${$img}/CloudNumber/home/22.png`" alt="">
            </div>
            <div class="empowerment-title">全智能化管理</div>
            <div class="empowerment-text">平台上架商品与实体门店商品库存实时同步。平台订单自动化处理，不影响线下销售。经营数据的在线管理，以实时了解经营状况。</div>
          </div>
          <div class="empowerment-item   wow fadeInUp" data-wow-delay="0.2s">
            <div class="empowerment-top">
              <img :src="`${$img}/CloudNumber/home/23.png`" alt="">
            </div>
            <div class="empowerment-title">多重运营营销</div>
            <div class="empowerment-text">线上商品优化，多重营销，可根据不同门店制定相对应的运营策略和计划。</div>
          </div>
          <div class="empowerment-item   wow fadeInUp" data-wow-delay="0.2s">
            <div class="empowerment-top">
              <img :src="`${$img}/CloudNumber/home/24.png`" alt="">
            </div>
            <div class="empowerment-title">共享药学中心</div>
            <div class="empowerment-text">“在线购药”一站式满足用户从健康咨询、快速问诊到线上购药的多重需求，真正实现线上医疗健康服务的安全、便捷、规范。</div>
          </div>
          <div class="empowerment-item   wow fadeInUp" data-wow-delay="0.3s">
            <div class="empowerment-top">
              <img :src="`${$img}/CloudNumber/home/25.png`" alt="">
            </div>
            <div class="empowerment-title">满足多样购药场景</div>
            <div class="empowerment-text">提供门店自助购药、到店自提、配送到家三大模式，满足客户多样化场景与需求。</div>
          </div>
          <div class="empowerment-item   wow fadeInUp" data-wow-delay="0.3s">
            <div class="empowerment-top">

              <img :src="`${$img}/CloudNumber/home/26.png`" alt="">
            </div>
            <div class="empowerment-title">健康信息管理</div>
            <div class="empowerment-text">支持在线问诊、在线处方、O2O送药上门服务等，全面建立顾客的健康信息。</div>
          </div>
          <div class="empowerment-item   wow fadeInUp" data-wow-delay="0.3s">
            <div class="empowerment-top">
              <img :src="`${$img}/CloudNumber/home/27.png`" alt="">
            </div>
            <div class="empowerment-title">对接医保</div>
            <div class="empowerment-text">支持各地医保对接，医保药品自动生成医保单据，店员无需二次录入。</div>
          </div>
        </div>
      </div>
      <div class="box" style="position: relative;">
        <div id="medicalDevices" style="position: absolute;top:-80px"></div>
        <div class="All-title  wow fadeInUp" data-wow-delay="0.1s">
          <div class="cnmmon-text">
            医疗器械与产品
            <div class="cnmmon-line"></div>
          </div>
        </div>
        <div class="medicalDevices">
          <div class="medicalDevices-item  wow fadeInUp" data-wow-delay="0.2s">
            <router-link target="_blank" :to="{ path: '/details', query: { id: 0 } }">
              <img :src="`${$img}CloudNumber/retail/2.png`" alt="">
              <div>头皮毛发检测仪（居家款）</div>
            </router-link>
          </div>
          <div class="medicalDevices-item  wow fadeInUp" data-wow-delay="0.2s">
            <router-link target="_blank" :to="{ path: '/details', query: { id: 1 } }">
              <img :src="`${$img}CloudNumber/retail/3.png`" alt="">
              <div>头皮毛发检测仪（医疗款）</div>
            </router-link>
          </div>
          <div class="medicalDevices-item  wow fadeInUp" data-wow-delay="0.2s">
            <router-link target="_blank" :to="{ path: '/details', query: { id: 2 } }">
              <img :src="`${$img}CloudNumber/retail/4.png`" alt="">
              <div>头皮毛发按摩仪</div>
            </router-link>
          </div>
          <div class="medicalDevices-item  wow fadeInUp" data-wow-delay="0.2s">
            <router-link target="_blank" :to="{ path: '/details', query: { id: 3 } }">
              <img :src="`${$img}CloudNumber/retail/5.png`" alt="">
              <div>头皮检测仪器</div>
            </router-link>
          </div>
          <div class="medicalDevices-item  wow fadeInUp" data-wow-delay="0.3s">
            <router-link target="_blank" :to="{ path: '/details', query: { id: 4 } }">
              <img :src="`${$img}CloudNumber/retail/6.png`" alt="">
              <div>理然香氛去屑洗发水</div>
            </router-link>
          </div>
          <div class="medicalDevices-item  wow fadeInUp" data-wow-delay="0.3s">
            <router-link target="_blank" :to="{ path: '/details', query: { id: 5 } }">
              <img :src="`${$img}CloudNumber/retail/7.png`" alt="">
              <div>生姜洗发水育发液</div>
            </router-link>
          </div>
          <div class="medicalDevices-item  wow fadeInUp" data-wow-delay="0.3s">
            <router-link target="_blank" :to="{ path: '/details', query: { id: 6 } }">
              <img :src="`${$img}CloudNumber/retail/8.png`" alt="">
              <div>谜草集 毛囊炎洗发水</div>
            </router-link>
          </div>
          <div class="medicalDevices-item wow fadeInUp" data-wow-delay="0.3s">
            <router-link target="_blank" :to="{ path: '/details', query: { id: 7 } }">
              <img :src="`${$img}CloudNumber/retail/9.png`" alt="">
              <div>Fino美容复合精华洗发水</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer @currentChange="currentChange"></Footer>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
    if (this.$route.params.id) {
      document.querySelector(`#${this.$route.params.id}`).scrollIntoView(true);
    }

  },
  beforeDestroy() {
    this.wow.stop();
  },
  methods: {
    currentChange(a) {
      document.querySelector(`#${a}`).scrollIntoView(true);
    }
  },
  data() {
    return {
      wow: null,
    }
  }
}
</script>
<style>
.retailAdvantagesBg {
  overflow: hidden;
  background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber/home/46.png')
    no-repeat;
  background-size: cover;
}
</style>