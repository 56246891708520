var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headerBg"},[_c('div',{staticClass:"header box"},[_c('img',{staticClass:"header-logo",attrs:{"src":require("../assets/home/logo.png"),"alt":""}}),_c('ul',{staticClass:"header-tab"},[_c('li',{class:{ selectTab: _vm.selectTab == 0 },on:{"click":function($event){_vm.selectTab = 0;
          _vm.$router.push('/home');},"mouseenter":function($event){_vm.left = 2.5},"mouseleave":_vm.leaveTab}},[_vm._v(" 首页 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 1 },on:{"click":function($event){_vm.selectTab = 1;
          _vm.$router.push('/software');},"mouseenter":function($event){_vm.left = 14.8},"mouseleave":_vm.leaveTab}},[_vm._v(" 医疗软件开发 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 2 },on:{"click":function($event){_vm.selectTab = 2;
          _vm.$router.push('/wisdom');},"mouseenter":function($event){_vm.left = 31.5},"mouseleave":_vm.leaveTab}},[_vm._v(" 物联网智慧医疗 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 3 },on:{"click":function($event){_vm.selectTab = 3;
          _vm.$router.push('/retail');},"mouseenter":function($event){_vm.left = 46.5},"mouseleave":_vm.leaveTab}},[_vm._v(" 医药新零售 ")]),_c('li',{staticClass:"SolutionTab",class:{ selectTab: _vm.selectTab == 4 },on:{"click":function($event){_vm.selectTab = 4;
          _vm.$router.push('/detection');},"mouseenter":function($event){_vm.left = 59.5},"mouseleave":_vm.leaveTab}},[_vm._v(" 解决方案 "),_c('div',{staticClass:"Solution"},[_c('div',{on:{"click":function($event){return _vm.$router.push('/software')}}},[_c('div',[_c('img',{attrs:{"src":`${_vm.$img}/CloudNumber/home/40.png`,"alt":""}})]),_c('div',[_vm._v("医疗软件开发")])]),_c('div',{on:{"click":function($event){return _vm.$router.push('/wisdom')}}},[_c('div',[_c('img',{attrs:{"src":`${_vm.$img}/CloudNumber/home/41.png`,"alt":""}})]),_c('div',[_vm._v("物联网智慧医疗")])]),_c('div',{on:{"click":function($event){return _vm.$router.push('/retail')}}},[_c('div',[_c('img',{attrs:{"src":`${_vm.$img}/CloudNumber/home/42.png`,"alt":""}})]),_c('div',[_vm._v("医药新零售")])]),_c('div',{on:{"click":function($event){return _vm.$router.push('/detection')}}},[_c('div',[_c('img',{attrs:{"src":`${_vm.$img}/CloudNumber/home/43.png`,"alt":""}})]),_c('div',[_vm._v("互联网医疗智慧服务")])])])]),_c('div',{staticClass:"Smegma"}),_c('li',{class:{ selectTab: _vm.selectTab == 5 },on:{"click":function($event){_vm.selectTab = 5;
          _vm.$router.push('/news');},"mouseenter":function($event){_vm.left = 71.3},"mouseleave":_vm.leaveTab}},[_vm._v(" 新闻资讯 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 6 },on:{"click":function($event){_vm.selectTab = 6;
          _vm.$router.push('/aboutUs');},"mouseenter":function($event){_vm.left = 83.4},"mouseleave":_vm.leaveTab}},[_vm._v(" 关于我们 ")]),_c('li',{class:{ selectTab: _vm.selectTab == 7 },on:{"click":function($event){_vm.selectTab = 7;
          _vm.$router.push('/contactUs');},"mouseenter":function($event){_vm.left = 95.2},"mouseleave":_vm.leaveTab}},[_vm._v(" 联系我们 ")]),_c('div',{staticClass:"buoy",style:({ left: _vm.left + '%' })},[_c('div',{staticClass:"sds"})])])]),_c('div',{staticClass:"suspendedTab"},[_c('div',{staticStyle:{"position":"relative"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"suspended suspended4"},[_c('div',{staticClass:"suspendedBox",staticStyle:{"top":"192px"},on:{"click":_vm.goToTop}},[_c('img',{staticClass:"item-img1",attrs:{"src":require("../assets/fixed/4.png"),"alt":""}}),_c('img',{staticClass:"item-img2",attrs:{"src":require("../assets/fixed/8.png"),"alt":""}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suspended"},[_c('div',{staticClass:"suspendedBox suspendedBox1",staticStyle:{"top":"0"}},[_c('div',{staticClass:"hoverPhone"},[_vm._v("400-681-0626")]),_c('div',[_c('img',{staticClass:"item-img1",attrs:{"src":require("../assets/fixed/1.png"),"alt":""}}),_c('img',{staticClass:"item-img2",attrs:{"src":require("../assets/fixed/5.png"),"alt":""}}),_c('div',[_vm._v("热线")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suspended"},[_c('div',{staticClass:"suspendedLeft"},[_c('img',{staticClass:"suspended-images",attrs:{"src":require("../assets/fixed/9.png"),"alt":""}})]),_c('div',{staticClass:"suspendedBox",staticStyle:{"top":"63px"}},[_c('div',[_c('img',{staticClass:"item-img1",attrs:{"src":require("../assets/fixed/2.png"),"alt":""}}),_c('img',{staticClass:"item-img2",attrs:{"src":require("../assets/fixed/6.png"),"alt":""}}),_c('div',[_vm._v("公众号")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suspended"},[_c('div',{staticClass:"suspendedLeft"},[_c('img',{staticClass:"suspended-images",attrs:{"src":require("../assets/fixed/10.png"),"alt":""}})]),_c('div',{staticClass:"suspendedBox",staticStyle:{"top":"128px"}},[_c('div',[_c('img',{staticClass:"item-img1",attrs:{"src":require("../assets/fixed/3.png"),"alt":""}}),_c('img',{staticClass:"item-img2",attrs:{"src":require("../assets/fixed/7.png"),"alt":""}}),_c('div',[_vm._v("企业微信")])])])])
}]

export { render, staticRenderFns }