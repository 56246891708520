<template>
  <div>
    <Header></Header>
    <div class="info">
      <div class="banner">
        <div class="banner-box3 box">
          <div class="banner-title  wow fadeInUp">新闻资讯</div>
          <div class="banner-detail  wow fadeInUp">汇聚行业动态 全面助力医疗数智化转型</div>
        </div>
        <img :src="`${$img}CloudNumber/banner/4.png?v=1`" alt="" class="banner-img">
      </div>
      <div class="box">
        <div class="top">
          <span style="cursor: pointer;" @click="$router.push(`/home`)">首页</span> > <span style="cursor: pointer;" @click="$router.push(`/news`)">新闻动态</span> > <span style="cursor: pointer;" @click="$router.push(`/news`)">企业动态</span>
        </div>
        <info1 v-if="id==1"></info1>
        <info2 v-if="id==2"></info2>
        <info3 v-if="id==3"></info3>
        <info4 v-if="id==4"></info4>
        <info5 v-if="id==5"></info5>
        <info6 v-if="id==6"></info6>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import info1 from "@/components/info1.vue";
import info2 from "@/components/info2.vue";
import info3 from "@/components/info3.vue";
import info4 from "@/components/info4.vue";
import info5 from "@/components/info5.vue";
import info6 from "@/components/info6.vue";
export default {
  components: {
    Footer,
    Header,
    info1,
    info2,
    info3,
    info4,
    info5,
    info6,
  },
  data() {
    return {
      wow: null,
      id: 1
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
  },
  beforeDestroy() {
    this.wow.stop()
  },

  mounted() {
    this.wow = new this.$wow.WOW({ live: false })
    this.wow.init()
  }

}
</script>

<style>
.list img {
  width: 100% !important;
}
.m3nContent {
  font-size: 16px;
  line-height: 32px;
  color: #555555;
  padding: 10px 0px;
}
</style>