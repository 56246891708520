<template>
  <div>
    <Header></Header>
    <div class="contactUs">
      <div class="banner">
        <div class="banner-box2 box">
          <div class="banner-title wow fadeInUp">联系我们</div>
          <div class="banner-detail wow fadeInUp">用先进技术帮助医疗机构提供高品质、可信赖的医疗服务赋能医疗机构全方位数智化转型，实现高质量可持续发展，帮助医疗机构开源、节流、增效，从优秀到卓越。</div>
        </div>
        <img :src="`${$img}CloudNumber/banner/6.png?v=1`" alt="" class="banner-img">
      </div>
      <div class="contactUsBoxBg ">
        <div class="contactUsabsolute"></div>
        <div class="contactUs-left">
          <div>云数智康总部</div>
          <div></div>
          <div class="contactUs-text">电话：400-681-0626</div>
          <div class="contactUs-text">地址：北京市丰台区广安路1幢-1层 </div>
          <div class="contactUs-text">邮编：100162</div>
        </div>

      </div>
      <div class="contactUs-Box box">
        <div>
          <div class="m2lx_bgimg"></div>
          <div class="m2lxLayer">
            <strong>北京总部</strong>
            地址：北京市丰台区广安路1幢-1层<br>
            电话：400-681-0626<br>
            邮编：100162
          </div>
        </div>
        <div>
          <div class="m2lx_bgimg"></div>
          <div class="m2lxLayer">
            <strong>云数智康深圳分公司</strong>
            地址：广东省深圳市龙华区新区大道新龙大厦5F<br>
            电话：400-681-0626<br>
            邮编：518048
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      wow: null,
    }
  },
  mounted() {

    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
  },
  beforeDestroy() {
    this.wow.stop();
  },
}
</script>

<style lang="scss">
.contactUsBoxBg {
  background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber/home/104.png');
  background-size: cover;
  background-position: center;
  height: 720px;
  padding-top: 20px;
  margin-bottom: 100px;
  position: relative;
}
.contactUsabsolute {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}
.contactUs-Box {
  margin: 60px auto 100px;
  display: flex;
  justify-content: space-between;
  > div {
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber/home/102.png')
      no-repeat;
    background-size: 100% 100%;
    width: 48.5%;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    .m2lxLayer {
      font-size: 16px;
      line-height: 30px;
      padding: 0px 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 45px;

      strong {
        font-size: 24px;
        line-height: 34px;
        font-weight: normal;
        display: block;
        margin-bottom: 12px;
      }
    }
  }
}
</style>