<template>
  <div>
    <div class="title">“国考”绩效考核如何稳中取胜？</div>
    <div class="date">2023-02-01</div>
    <div class="line"></div>
    <div class="m3nLtop">
      <div class="m3nContent">
        <section powered-by="xiumi.us" style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; color: rgb(34, 34, 34); font-family: system-ui, -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; text-align: justify; white-space: normal; background-color: rgb(255, 255, 255); visibility: visible; box-sizing: border-box !important; overflow-wrap: break-word !important;">
          <p>公立医院“国考”步伐片刻未歇。</p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; visibility: visible; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>继2021与2020年“国考”成绩公布时间仅三个月后，新一轮2022年公立三级、二级医院的绩效考核悄然启动。</p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; visibility: visible; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>评审规范重调，考核难度加码，医院该如何做好绩效管理，稳中取胜呢？</p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; visibility: visible; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>本文整理了国考评审标准的重点难点，以及绩效管理创新方法，希望为大家提供借鉴和参考。</p>
          <p><br></p>
        </section>
        <p></p>
        <section powered-by="xiumi.us">
          <section>
            <section powered-by="xiumi.us">
              <p><strong>“国考”马不停蹄备考难度加剧</strong></p>
            </section>
          </section>
        </section>
        <section powered-by="xiumi.us">
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; visibility: visible; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>10月13日，国家卫健委正式发布关于启动2022年度二级和三级公立医院绩效考核有关工作的通知。</p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; visibility: visible; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
        </section>
        <section powered-by="xiumi.us">
          <section>
            <p style="text-align: center;"><img src="http://www.karrytech.com/uploads/images/2023/0201/pfSqXbEw55u0xZXwH5Nr94Xc7Qpn9TIC4VgTeZ0p.png" alt="undefined"></p>
          </section>
        </section>
        <section powered-by="xiumi.us">
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>《通知》明确提出，已参加2021年度绩效考核的医院应当按照要求，于每月15日前将上一个月的住院病案首页数据上传至国家医院质量监测系统。而新纳入考核的医院，也应当于11月15日前，将2020全年、2021全年、2022年1月至10月的住院病案首页数据上传至国家医院质量监测系统。并在2022年12月起，每月15日前完成上一个月住院病案首页数据上传工作。</p>
          <p><br></p>
          <p>在各大医院紧锣密鼓的备考之际，考核难度也迎来了升温。今年国家卫健委分别发布了《国家三级公立医院绩效考核操作手册（2022版）》和《国家二级公立医院绩效考核操作手册（2022版）》，修订内容主要有三大点：增设或修订延伸指标；补充及更新相关工作要求；进一步明确指标内涵。<br></p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>例如三级公立医院绩效考核操作手册（2022版），在指标18~19原有内容之上，增设了对基本药物处方使用品种数量占比的考核内容；在指标21原有内容之上，增设国家组织药品集中采购中选药品完成比例的考核内容。并将最新规范性文件作为指标统计依据，如加入《关于印发公立医院高质量发展促进行动（2021-2025年）的通知》、《国务院办公厅关于推动公立医院高质量发展的意见》等文件。</p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>显然，“国考”对公立医院管理提出了更新更高的要求。</p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
        </section>
        <section powered-by="xiumi.us">
          <section>
            <section powered-by="xiumi.us">
              <p><strong>指标体系下，</strong><strong>绩效管理如何做？</strong></p>
            </section>
          </section>
        </section>
        <section powered-by="xiumi.us">
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>国家考核的主线非常清晰，即重效益、控规模、严质量，整个指标体系由医疗质量、运营效率、持续发展、满意度评价四个方面组成。</p>
        </section>
        <section powered-by="xiumi.us">
          <section>
            <section>
              <section powered-by="xiumi.us">
                <section>
                  <section powered-by="xiumi.us">
                    <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                  </section>
                </section>
              </section>
            </section>
            <section>
              <section powered-by="xiumi.us">
                <p>要点一：调整费用结构</p>
              </section>
            </section>
          </section>
        </section>
        <section powered-by="xiumi.us">
          <p>在“向精细化管理要效益”的时代，医院需要不断提高业财融合的运营管理能力，调整好费用结构，通过成本一体化方案实现成本控制全过程管理。例如科室成本核算、项目成本核算、DRGs 病种成本核算、多维成本分析等。做好费用结构控制，医院才得以在新医改趋势下赢得先机。<br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p><br></p>
        </section>
        <section powered-by="xiumi.us">
          <section>
            <section powered-by="xiumi.us">
              <p>要点二：加强内部控制和运营机制的建设</p>
            </section>
          </section>
          <section>
            <section powered-by="xiumi.us">
              <section>
                <section powered-by="xiumi.us">
                  <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section powered-by="xiumi.us">
          <p>一个医院是否实施了有效的内部控制和运营机制，直接关系到医院运营管理状况的好坏。所以医院必须要不断加强完善，坚持“无计划勿行动、无预算勿支出”，以保证运营机制的高效，保证财务报表数据的真实性和可靠性，同时，保证医院的经济活动和业务活动的合法合规性，对医院运营管理、经济活动、财务收支、业务往来等活动进行有效监管。</p>
        </section>
        <section powered-by="xiumi.us">
          <section>
            <section powered-by="xiumi.us">
              <section>
                <section powered-by="xiumi.us">
                  <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                </section>
              </section>
            </section>
          </section>
          <section>
            <section powered-by="xiumi.us">
              <p>要点三：树立全面预算管理理念</p>
            </section>
          </section>
          <section>
            <section powered-by="xiumi.us">
              <section>
                <section powered-by="xiumi.us">
                  <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section powered-by="xiumi.us">
          <p>&nbsp;全面预算管理，是医院实现预定期内战略目标和经营目标的重要方法和工具，以实现医院战略为目标，以资源配置为核心。</p>
          <p>在《关于加强三级公立医院绩效考核工作的意见》中就明确提出了，医院要推进预算与绩效管理一体化。例如，医院可以通过预算管理确定月、季度资金配置状况及使用额度，安排合理的项目实施进度，为绩效考核提供依据。</p>
          <p><br></p>
        </section>
        <section powered-by="xiumi.us">
          <section>
            <section powered-by="xiumi.us">
              <p>要点四：提升数据质量</p>
            </section>
          </section>
          <section>
            <section powered-by="xiumi.us">
              <section>
                <section powered-by="xiumi.us">
                  <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                </section>
              </section>
            </section>
          </section>
        </section>
        <section powered-by="xiumi.us">
          <p>时间紧张、指标多、数据来源多等问题，使得医院在数据上报与数据质量方面的备考压力颇大，规范填写住院病案首页，保证数据一致性和准确性，做好数据质控工作，确保上报顺利，是当务之急。所以医院需要不断提升数据处理能力与精细化管理水平，一方面可以确保上报数据的准确性、完整性，另一方面也可以在日常管理中，通过指标数据的可视化、事中化监测及时纠正问题，加强薄弱环节管理，从而整体提升考核指标管理能力。</p>
        </section>
        <section powered-by="xiumi.us">
          <section>
            <section powered-by="xiumi.us">
              <section>
                <section powered-by="xiumi.us">
                  <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
                </section>
              </section>
            </section>
          </section>
          <section>
            <section powered-by="xiumi.us">
              <p><strong>国考加码，</strong><strong>绩效考核功在平时</strong></p>
            </section>
          </section>
        </section>
        <section powered-by="xiumi.us">
          <p><br></p>
          <p>一把尺子量全国。作为检验公立医院改革发展成效的一把标尺，国考结果与财政补助、医保支付、绩效工资总量以及院长的薪酬任免、奖惩等直接挂钩，牵动着所有医院的心。</p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>面对如此压力，医院可以怎样调整管理理念、技术和方法，以适应新时代的绩效考核呢？</p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>云数智康基于十八年在医院信息化领域的深耕与实践，沉淀了千家医疗机构的管理经验，打造了智慧管理HRP解决方案，通过精细化财务核算、全面预算/成本/绩效管理、全生命周期供应链管理、人事管理等核心模块，助力医院在国考中取得优秀成绩。</p>
          <p><br></p>
          <p><strong>1、合理运用绩效考核工具，</strong><strong>有效实施考核</strong></p>
        </section>
        <section powered-by="xiumi.us">
          <section>
            <section powered-by="xiumi.us">
              <section>
                <p><strong><br></strong></p>
              </section>
            </section>
          </section>
        </section>
        <section powered-by="xiumi.us">
          <p>有效的绩效考核有赖于整个绩效管理工具的成功开展，而成功的绩效管理也需要有效的绩效考核来支撑。</p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>医院绩效考核的难点是如何保证考核目标和目标实现两者之间相辅相成、辩证统一，对此医院可以借用绩效管理的必备工具，例如云数智康智慧管理HRP解决方案中的科室/病种/项目成本管理、绩效管理、总账管理工具等等，把RBRVS、GRGs、BSC以及KPI等纳入绩效考核，促进合理控制费用，提高医疗服务质量和效率、规范医疗行为。</p>
          <p style="text-align: center;"><img src="http://www.karrytech.com/uploads/images/2023/0201/ltb4MkAhB04hjQFWwwh9ftf0txIxo5Nnk8slEAA9.png"></p>
          <p><br></p>
        </section>
        <section powered-by="xiumi.us">
          <p><strong>2、建立全面预算管理制度，提升医院管理水平</strong><br></p>
          <p><strong><br></strong></p>
        </section>
        <section powered-by="xiumi.us">
          <p>全面预算管理是循环往复的过程，需要经历预算编制、预算执行、预算调整、预算考评、分析与结果反馈。通过全面预算管理，能够促进医院优化资源配置，促进医院提升收入、降低成本。</p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>通过云数智康全面预算管理模块的运用，既可以提升医院对成本的管控效率，提高医院成本管理水平，还能减少医院常规成本管理的工作中因各种因素导致的失误。</p>
          <p><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p>更值得一提的是，将医院的各种系统平台，例如HIS信息系统、医院财务系统、固定资产系统进行对接，使得医院大数据的共享，进一步提升医院的业务、财务的一体化管理，让医院的成本得到有效控制，实现医院成本工作的规范性、科学性，并为医院的长期发展提供保障。</p>
          <p><br></p>
        </section>
        <section powered-by="xiumi.us">
          <section style="text-align: center;"><img src="http://www.karrytech.com/uploads/images/2023/0201/j29q9FxQQbMcTzvysggutK5JoYHzUIEemEMwvAmv.png"><br></section>
        </section>
        <section powered-by="xiumi.us">
          <p style="text-align: center;"><img src="http://www.karrytech.com/uploads/images/2023/0201/bNlLrIyLM6FCPdCPViTko7u2wydfBUxk1kmpMTek.png"><br style="margin: 0px; padding: 0px; outline: 0px; max-width: 100%; box-sizing: border-box !important; overflow-wrap: break-word !important;"></p>
          <p><br></p>
          <p>信息化建设是支撑医院不断向前的重要保障，云数智康十八年专注为医疗机构智慧赋能，提供定制化智慧管理解决方案，助力医院运营管理迈上新台阶！</p>
        </section>
        <p></p>
        <p><br></p>
        <p><br style="white-space: normal;"></p>
        <p><br></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>