<template>
  <div>
    <Header></Header>
    <div class="news">
      <div class="banner">
        <div class="banner-box3 box">
          <div class="banner-title  wow fadeInUp">新闻资讯</div>
          <div class="banner-detail  wow fadeInUp">汇聚行业动态 全面助力医疗数智化转型</div>

        </div>
        <img :src="`${$img}CloudNumber/banner/4.png?v=1`" alt="" class="banner-img">

      </div>
      <div style="position: relative;">
        <div id="Journalism-tab" style="position: absolute;top:-80px"></div>
      </div>
      <div class="Journalism-tab box wow fadeInUp" data-wow-delay="0.1s">

        <div :class="{selectJouTab: selectTab == 1}" @click="selectTab=1">企业动态</div>
      </div>
      <div class="Journalism">
        <div class="Journalism-item " id="dynamic">
          <div>
            <div class="box  Journalism-item-line  wow fadeInUp" data-wow-delay="0.1s" style="display:flex ;flex-wrap: wrap;justify-content: space-between;">

              <div class="item" v-for="(item, index) in StyleimgList" :key="index">
                <router-link target="_blank" :to="{ path: '/info', query: { id: index+1 } }">
                  <div class="item-img item-imgT">

                    <img :src="item.img" alt="">
                  </div>
                  <div class="item-bottom">
                    <div style="text-align: left;line-height: 25px;">
                      {{ item.text }}
                    </div>
                    <div class="date">
                      {{ item.date }}
                    </div>
                  </div>
                </router-link>
              </div>

            </div>
          </div>
        </div>
        <template v-if="bol">
          <prop @cancel="close" :isSelect="isSelect"></prop>
        </template>
      </div>
    </div>
    <Footer @currentChange="currentChange"></Footer>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
  components: {
    Footer,
    Header
  },
  methods: {
    currentChange(a) {
      this.selectTab = a
      document.querySelector(`#Journalism-tab`).scrollIntoView(true);
    },
    close() {
      document.body.style.height = " 100%"
      document.body.style.overflow = "auto";
      this.bol = false;
    },
    show(index) {
      document.body.style.height = "auto"
      document.body.style.overflow = "hidden";
      this.bol = true;


      this.isSelect = index;

      console.log(index)

    },
    lookMore() {

      this.imgList = [
        ...this.imgList,
        ...this.addimgList
      ]

      if (this.imgList.length = 12) {
        this.isNoAdd = false
      }
    },
    lookMoreT() {

      this.StyleimgList = [
        ...this.StyleimgList,
        ...this.addStyleimgList
      ]

      if (this.StyleimgList.length = 12) {
        this.isNoAddT = false
      }
    }
  },
  data() {
    return {
      selectTab: 1,
      bol: false,
      isSelect: 0,
      isNoAdd: true,
      isNoAddT: true,
      wow: null,
      StyleimgList: [
        {
          img: `${this.$img}CloudNumber/news/5.jpg`,
          text: "医院管理系统，ODR助力PDCA循环，共同改善...",
          date: '2023-02-09'
        },
        {
          img: `${this.$img}CloudNumber/news/7.png`,
          text: "“国考”绩效考核如何稳中取胜？",
          date: '2023-02-01'
        },
        {
          img: `${this.$img}CloudNumber/news/2.jpg`,
          text: "多省发文！公立医院高质量发展的路该这样走！",
          date: '2022-12-08'
        },
        {
          img: `${this.$img}CloudNumber/news/3.jpg`,
          text: "一文教你医院互联互通标准化成熟度测评中对...",
          date: '2022-12-02'
        },
        {
          img: `${this.$img}CloudNumber/news/4.jpg`,
          text: "医院高质量发展需要怎样的HIS系统？",
          date: '2022-11-09'
        },

        {
          img: `${this.$img}CloudNumber/news/6.jpg`,
          text: "智慧医院建设路上，必须关注的7大信息化...",
          date: '2021-12-28'
        },]

    }
  },
  beforeDestroy() {
    this.wow.stop()
  },

  mounted() {

    if (this.$route.params.id) {
      this.selectTab = this.$route.params.id
      document.querySelector(`#Journalism-tab`).scrollIntoView(true);
    }

    this.wow = new this.$wow.WOW({ live: false })
    this.wow.init()


  }
}
</script>

<style lang="scss">
.item {
  border-radius: 0.3em;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px !important;
  transition: all 0.5s ease-in-out;
  img {
    width: 150%;
    transition: all 0.5s ease-in-out;
    vertical-align: middle;
  }
}
.item:hover {
  transform: translateY(-10px);

  img {
    transform: scale(1.05);
  }
}
</style>