<template>
  <div>
    <Header></Header>
    <div class="details">
      <div class="banner">
        <div class="banner-box2 box">
          <div class="banner-title wow fadeInUp">医药新零售</div>
          <div class="banner-detail wow fadeInUp">通过线上线下及公域私域相融合，提供导购数字化、营销数字化、会员数字化等赋能工具，帮助医药门店实现全时全域经营，多位一体数字化构建可持续智慧增长。</div>
        </div>
        <img :src="`${$img}CloudNumber/banner/7.png?v=1`" alt="" class="banner-img">
      </div>
      <div class="box">
        <div class="top">
          <span style="cursor: pointer;" @click="$router.push(`/retail`)">医药新零售</span> > <span style="cursor: pointer;">{{obj.title}}</span>
        </div>
        <div class="details-text">{{obj.title}}</div>
        <img :src="obj.src" alt="">
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
  components: {
    Footer,
    Header
  },
  data() {
    return {
      wow: null,
      obj: {
        title: "头皮毛发检测仪（居家款）",
        src: `${this.$img}CloudNumber/details/1.png`
      },
      list: [
        {
          title: "头皮毛发检测仪（居家款）",
          src: `${this.$img}CloudNumber/details/1.png`
        },
        {
          title: "头皮毛发检测仪（医疗款）",
          src: `${this.$img}CloudNumber/details/2.png`
        },
        {
          title: "头皮毛发按摩仪",
          src: `${this.$img}CloudNumber/details/3.png`
        },
        {
          title: "头皮检测仪器",
          src: `${this.$img}CloudNumber/details/4.jpg`
        },
        {
          title: "理然香氛去屑洗发水",
          src: `${this.$img}CloudNumber/details/5.png`
        },
        {
          title: "生姜洗发水育发液",
          src: `${this.$img}CloudNumber/details/6.png`
        },
        {
          title: "谜草集 毛囊炎洗发水",
          src: `${this.$img}CloudNumber/details/7.png`
        },
        {
          title: "Fino美容复合精华洗发水",
          src: `${this.$img}CloudNumber/details/8.png`
        },
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.obj = this.list[this.$route.query.id]
    }
  },
  beforeDestroy() {
    this.wow.stop()
  },
  mounted() {
    this.wow = new this.$wow.WOW({ live: false })
    this.wow.init()
  }
}
</script>

<style>
</style>