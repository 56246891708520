<template>
  <div>
    <!-- <Header :key="$route.fullPath"></Header> -->
    <!-- <keep-alive include="team,report,search"> -->
    <router-view />
    <!-- </keep-alive> -->

    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
  computed: {},
  components: {
    Header,
    Footer,
  },
};
</script>
  <style lang="scss">
@import '../style/index.scss';

body {
  overflow-x: hidden;
  font-size: 16px;
}

div,
ul,
li,
span,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
}

li {
  list-style-type: none;
}
</style>
