import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/home'
import index from '@/views/index'
import software from '@/views/software'
import wisdom from '@/views/wisdom'
import detection from '@/views/detection'
import news from '@/views/news'
import aboutUs from '@/views/aboutUs'
import contactUs from '@/views/contactUs'
import retail from '@/views/retail'
import info from '@/views/info'
import details from '@/views/details'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'index',
  redirect: '/home',
  component: index,
  children: [{
    path: '/home',
    name: 'home',
    component: Home
  }]
}, {
  path: '/software',
  name: 'software',
  component: software
}, {
  path: '/wisdom',
  name: 'wisdom',
  component: wisdom
}, {
  path: '/detection',
  name: 'detection',
  component: detection
}, {
  path: '/news',
  name: 'news',
  component: news
}, {
  path: '/aboutUs',
  name: 'aboutUs',
  component: aboutUs
}, {
  path: '/contactUs',
  name: 'contactUs',
  component: contactUs
}, {
  path: '/retail',
  name: 'retail',
  component: retail
}, {
  path: '/info',
  name: 'info',
  component: info
}, {
  path: '/details',
  name: 'details',
  component: details
}]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
