<template>
  <div>
    <Header></Header>
    <div class="software">

      <div class="banner">
        <div class="banner-box2 box">
          <div class="banner-title wow fadeInUp">医疗软件开发</div>
          <div class="banner-detail wow fadeInUp">为医疗机构提供集IaaS、PaaS、SaaS为一体的综合云服务解决方案，具有高稳定性、统一管理、可视化运营等特点，助力医疗机构构建稳定安全的云环境和健康的云生态。</div>
        </div>
        <img :src="`${$img}CloudNumber/banner/5.png?v=1`" alt="" class="banner-img">
      </div>
      <div class="servicesBg">
        <div class="box " style="position: relative;">
          <div id="service" style="position: absolute;top:-80px"></div>
          <div class="All-title wow fadeInUp" data-wow-delay="0.1s">
            <div class="cnmmon-text">
              产品服务
              <div class="cnmmon-line"></div>
            </div>
          </div>
          <div class="services wow fadeInUp" data-wow-delay="0.4s">
            <div class="services-item">
              <div class="services-top">
                <img :src="`${$img}CloudNumber/home/12.png`" alt="">
              </div>
              <div class="services-title">快速在线问诊</div>
              <div class="services-text">
                方便准确的与医生进行沟通，维持医生与患者长期稳定的沟通。实现让患者足不出户就能问医，减少医患矛盾。
              </div>
            </div>
            <div class="services-item">
              <div class="services-top">
                <img :src="`${$img}CloudNumber/home/13.png`" alt="">
              </div>
              <div class="services-title">智能健康问诊</div>
              <div class="services-text">
                可随时随地通过可穿戴设备实时监测体征数据，了解自身健康状况，为您的健康全面护航
              </div>
            </div>
            <div class="services-item">
              <div class="services-top">
                <img :src="`${$img}CloudNumber/home/14.png`" alt="">
              </div>
              <div class="services-title">健康用药管家</div>
              <div class="services-text">
                帮助医院以及医生为患者提供完善的诊后用药服务，减轻药师工作压力，提高患者用药依从性。
              </div>
            </div>
            <div class="services-item">
              <div class="services-top">
                <img :src="`${$img}CloudNumber/home/15.png`" alt="">
              </div>
              <div class="services-title">智能AI在线</div>
              <div class="services-text">
                在线问诊植入AI能力，助力提升医患问答效率，减少医生录入工作，辅助医生高质量解答患者疑问。
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="ecology" style="position: relative;">
        <div id="Ecology" style="position: absolute;top:-60px"></div>
        <div class="All-title wow fadeInUp" data-wow-delay="0.1s">
          <div class="cnmmon-text">
            产品生态
            <div class="cnmmon-line"></div>
          </div>
        </div>
        <img src="../assets/software/6.png" alt="" class="wow fadeInUp" data-wow-delay="0.3s">
      </div>
      <div class="box" style="position: relative;">
        <div id="Scenario" style="position: absolute;top:-80px"></div>
        <div class="All-title wow fadeInUp" data-wow-delay="0.1s">
          <div class="cnmmon-text">
            应用场景
            <div class="cnmmon-line"></div>
          </div>
        </div>
        <div class="application ">
          <div class="application-item wow fadeInUp" data-wow-delay="0.1s">
            <div>
              <div class="app-title">互联网医院</div>
              <div class="app-text">
                为各级医院提供一系列解决方案，通过互联网信息技术连接医院、医生和患者，引导医疗资源下沉，优化医疗资源配置、提升医疗服务效率，打造中国最具技术领先优势的互联网诊疗平台。提升各区域的卫生信息化水平，提高卫生管理能力及管理效率。
              </div>
              <div class="app-box">
                <span>医疗资源下沉</span>
                <span>优化医疗资源</span>
              </div>
              <div class="app-box">
                <span>提升医疗效率</span>
                <span>打破传统医疗</span>
              </div>
            </div>
            <img src="../assets/software/7.png" alt="">
          </div>
          <div class="application-item wow fadeInUp" data-wow-delay="0.1s">
            <img src="../assets/software/8.png" alt="">
            <div>
              <div class="app-title">医院HIS</div>
              <div class="app-text">
                为各级医院提供一系列解决方案，帮助医院解决系统维护困难，医院间信息孤岛、体验不好等问题。实现医院互联，同时保障信息传输和数据安全，提供人工智能辅助，优化患者就医流程，降低医院的业务负载。
              </div>
              <div class="app-box">
                <span>解决维护困难</span>
                <span>加强医患体验</span>
              </div>
              <div class="app-box">
                <span>提高患者周转</span>
                <span>完善就医体验</span>
              </div>
            </div>

          </div>
          <div class="application-item wow fadeInUp" data-wow-delay="0.1s">
            <div>
              <div class="app-title">社区门诊</div>
              <div class="app-text">
                拥有覆盖全国的医疗资源和合作伙伴，可为各中西医诊所提供综合解决方案，针对诊所的特点，实现多科室覆盖，预约挂号，远程问诊，中医开方，中医开药，全科辅助诊断，影像智能分析，电子病历等功能和场景，为诊所助力，提升诊所能力。
              </div>
              <div class="app-box">
                <span>提升医生能力</span>
                <span>家医团队就医</span>
              </div>
              <div class="app-box">
                <span>实现多科覆盖</span>
                <span>提升诊所能力</span>
              </div>
            </div>
            <img src="../assets/software/9.png" alt="">
          </div>
          <div class="application-item wow fadeInUp" data-wow-delay="0.1s">
            <img src="../assets/software/10.png" alt="">
            <div>
              <div class="app-title">居家管理</div>
              <div class="app-text">
                关注家庭用户健康，为家庭客户提供综合健康解决方案，包涵智能可穿戴硬件，智能人工智能服务等。依托强大的医疗资源和多年的服务经验，为家庭客户提供在线问诊、预约挂号、医学百科、视频问诊等功能，为家庭健康保驾护航。
              </div>
              <div class="app-box">
                <span>主动健康管理</span>
                <span>居家医疗智化</span>
              </div>
              <div class="app-box">
                <span>健康生活方式</span>
                <span>满足康养需求</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <Footer @currentChange="currentChange"></Footer>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.wow = new this.$wow.WOW({ live: false });
    this.wow.init();
    if (this.$route.params.id) {
      document.querySelector(`#${this.$route.params.id}`).scrollIntoView(true);
    }
  },
  beforeDestroy() {
    this.wow.stop();
  },
  methods: {
    currentChange(a) {
      $.fn.fullpage.moveTo(a + 1);
    }
  },
  data() {
    return {
      wow: null,
    }
  }
}
</script>

<style>
.servicesBg {
  overflow: hidden;
  background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber/home/45.png')
    no-repeat;
  background-size: cover;
}
.services-text {
  text-align: left;
  text-align: justify;
}
</style>